// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Link, { LinkProps } from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ReactNode } from "react";
import {
  GraftIdProps,
  GraftTypographyVariantOptions,
  GraftTypographyWeightOptions,
} from "../../types/GraftProps";

export type GraftLinkProps = GraftIdProps &
  Pick<LinkProps, "color" | "onClick" | "underline"> & {
    disabled?: boolean;
    children: ReactNode;
    variant?: GraftTypographyVariantOptions;
    weight?: GraftTypographyWeightOptions;
  };

export function GraftLink({
  id,
  graftTestId,
  disabled,
  children,
  color = "primary",
  onClick,
  underline = "always",
  variant,
  weight = "regular",
}: GraftLinkProps) {
  // NOTE(eschkufz): Ideally this stuff would all happen in a style file, but mui doesn't give you a way to trap
  //  the value of color in the global theme override.

  const s = color as string;
  const index = s.indexOf(".");
  const prefix = index !== -1 ? s.substring(0, index) : s;
  const suffix = index !== -1 ? s.substring(index + 1) : "";

  let restingColor = color;
  if (disabled && color === `${prefix}.blue`) {
    restingColor = "secondary.dark";
  }

  let hoverColor;
  switch (suffix) {
    case "white":
      hoverColor = `${prefix}.white`;
      break;
    case "blue":
      hoverColor = `${prefix}.darkBlue`;
      break;
    case "main":
      hoverColor = `${prefix}.dark`;
      break;
    case "dark":
      hoverColor = `${prefix}.darker`;
      break;
    case "darker":
      restingColor = color;
      hoverColor = `${prefix}.darkest`;
      break;
    default:
      restingColor = `${prefix}.main`;
      hoverColor = `${prefix}.dark`;
      break;
  }

  return (
    <Link
      id={id}
      data-testid={graftTestId}
      className={`graft_weight_${weight}`}
      children={children}
      sx={{
        color: restingColor,
        "&:hover": {
          color: !disabled ? hoverColor : restingColor,
        },
      }}
      underline={disabled ? "none" : underline}
      variant={variant as keyof typeof Typography}
      onClick={!disabled ? onClick : undefined}
    />
  );
}
