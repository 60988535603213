// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { NonNull } from "../types/utility";

export class LocalStorage {
  private _storage: Storage;
  private readonly _key: string;

  constructor(key: string) {
    this._storage = window.localStorage;
    this._key = key;
  }

  get() {
    const value = this._storage.getItem(this._key);
    return value ? JSON.parse(value) : null;
  }

  set(value: NonNull<unknown>) {
    this._storage.setItem(this._key, JSON.stringify(value));
  }

  remove() {
    this._storage.removeItem(this._key);
  }

  clear() {
    this._storage.clear();
  }
}
