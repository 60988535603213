// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Box from "@mui/material/Box";
import Typography, { TypographyProps } from "@mui/material/Typography";
import { CSSProperties, ReactNode } from "react";
import {
  GraftIdProps,
  GraftStatusProps,
  GraftTypographyTransformOptions,
  GraftTypographyVariantOptions,
  GraftTypographyWeightOptions,
} from "../../types/GraftProps";
import { Styles } from "../../types/styles";
import { GraftStatusTooltip } from "../info/GraftStatusTooltip";
import { useLocalizationManager } from "../../hooks/managers/useLocalizationManager";
import { graftTheme } from "../../styles/themes/graft";

export type GraftTypographyProps = GraftIdProps &
  GraftStatusProps &
  Pick<
    TypographyProps,
    | "align"
    | "color"
    | "gutterBottom"
    | "noWrap"
    | "paragraph"
    | "overflow"
    | "textOverflow"
    | "lineHeight"
    | "letterSpacing"
  > & {
    weight?: GraftTypographyWeightOptions;
    transform?: GraftTypographyTransformOptions;
    breakAll?: boolean;
    ignoreNewline?: boolean;
    variant?: GraftTypographyVariantOptions;
    children: ReactNode;
  };

export function GraftTypography({
  id,
  graftTestId,
  disabled,
  loading,
  error,
  info,
  align = "left",
  color,
  weight = "regular",
  transform,
  gutterBottom = false,
  noWrap = false,
  breakAll = false,
  ignoreNewline = true,
  paragraph = false,
  overflow,
  textOverflow,
  lineHeight,
  letterSpacing,
  variant = "body1",
  children,
}: GraftTypographyProps) {
  const localizationManager = useLocalizationManager();
  const anyStatus = Boolean(disabled || loading || error || info);
  const baseStyle = (graftTheme.typography[variant] as CSSProperties) ?? {};

  transform =
    !transform && variant === "overline" ? "uppercase" : transform ?? "none";
  color = color ?? baseStyle.color ?? "secondary.darker";

  return (
    <Typography
      id={id}
      data-testid={graftTestId}
      className={`graft_weight_${weight} graft_transform_${transform}`}
      align={align}
      color={disabled ? "secondary.light" : color}
      gutterBottom={gutterBottom}
      noWrap={noWrap}
      lineHeight={lineHeight}
      letterSpacing={letterSpacing}
      paragraph={paragraph}
      variant={variant as keyof typeof Typography}
      component="div"
    >
      <Box sx={styles.outer}>
        <Box sx={[breakAll && styles.breakAll, { overflow }]}>
          <Box
            sx={[
              ignoreNewline ? {} : styles.newlines,
              { overflow, textOverflow },
            ]}
          >
            {localizationManager.maybeGet(children)}
          </Box>
        </Box>
        {anyStatus && (
          <Box sx={styles.tooltip}>
            <GraftStatusTooltip
              disabled={disabled}
              loading={loading}
              error={error}
              info={info}
            />
          </Box>
        )}
      </Box>
    </Typography>
  );
}

const styles: Styles = {
  outer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  tooltip: {
    paddingLeft: 0.5,
  },
  breakAll: {
    wordBreak: "break-all",
  },
  newlines: {
    whiteSpace: "pre-wrap",
  },
};
