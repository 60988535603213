// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GRAFT_DEFAULT_FONT_FAMILY } from "../../themes/graft";

export function muiLink() {
  return {
    root: {
      transition: "color .2s ease-in",
      ":hover": {
        cursor: "pointer",
      },
      fontFamily: GRAFT_DEFAULT_FONT_FAMILY,
    },
  };
}
