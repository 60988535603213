// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
export const ROOT_ROUTES = {
  root: "v3",
  projects: "projects",
  account: "account",
};

export const PROJECT_ROUTES = {
  create: "create",
  edit: "edit",
  delete: "delete",
  teammate: `teammate`,
  config: `config`,
  data: `data`,
  models: `models`,
  labs: "labs",
};
