// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ErrorInfo, FC, ReactNode, useEffect, useRef } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { publishError } from "../../services/logging/publishErrorMessage";
import { GraftErrorCard } from "./GraftErrorCard";

export type ErrorBoundaryProps = {
  children?: ReactNode;
  autoReset?: boolean;
  location: string;
};

export const GraftErrorBoundary: FC<ErrorBoundaryProps> = ({
  autoReset = false,
  children,
  location,
}) => {
  const path = useRef({});
  const onError = (error: Error, errorInfo: ErrorInfo) => {
    path.current = window.location.pathname;
    const errorStack = error?.stack;
    const componentStack = errorInfo?.componentStack;
    publishError({
      message: `ErrorBoundary caught an error: ${error}, boundary location: ${location}`,
      errorStack: errorStack != null ? String(errorStack) : undefined,
      componentStack:
        componentStack != null ? String(componentStack) : undefined,
    });
  };

  const FallBackComponent: FC<{
    error: Error;
    resetErrorBoundary: () => void;
  }> = ({ error, resetErrorBoundary }) => {
    useEffect(() => {
      const timer = autoReset
        ? setTimeout(() => {
            resetErrorBoundary();
          }, 5000)
        : undefined;
      if (path.current !== window.location.pathname) {
        resetErrorBoundary();
      }
      return () => clearTimeout(timer);
    }, []);

    // Note the error might not be an Error object, one can throw anything.
    return <GraftErrorCard message={String(error?.message ?? error)} />;
  };

  return (
    <ErrorBoundary FallbackComponent={FallBackComponent} onError={onError}>
      {children}
    </ErrorBoundary>
  );
};
