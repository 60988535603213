// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ContentRecord } from "../content";

export const ENUS_CONTENT: ContentRecord = {
  // Alert Details Page;
  page_alerts_title: "Alerts",
  page_alerts_subtitle:
    "Your current alerts are shown below. Press the create button to create more!",

  // Project Dashboard Page:
  page_dashboard_title: "Dashboard",
  page_dashboard_subtitle: null,

  // Data landing Tab:
  page_data_title: "Data",
  page_data_subtitle: "Manage credentials, data sources, and entities",

  // Data Details Page:
  page_data_sources_title: "Data Sources",
  page_data_sources_subtitle:
    "A way of connecting to data you want to work with. For example, a slack account, JIRA tickets, or a SQL database.",

  // Entity Details Page:
  page_entities_title: "Entities",
  page_entities_subtitle:
    "A collection of data that represents an object that you want to work with. For example, a blog post is an entity that consists of images and text.",
  page_entity_details_title: "Entity Details",

  //Enrichment type dialog
  dialog_enrichment_create_title:
    "What type of Enrichment would you like to add?",

  // Enrichments Details Page:
  page_enrichments_title: "Enrichments",
  page_enrichments_subtitle:
    "Enrichments for all Entities are shown below, please click the one you want to work on.",

  // Job Details Page:
  page_jobs_title: "Jobs",

  // Monitoring Page;
  page_monitoring_title: "Monitoring",

  // Project Details Page:
  page_projects_title: "Projects",

  // Security Details Page:
  page_security_title: "Security",
  page_security_subtitle:
    "To use the Graft APIs, create an API Key or Personal Access Token.",

  // Data Stepper:
  stepper_data_sources_1_step_name: "Select Data Source Type",
  stepper_data_sources_1_type_options_header: "Data Source Type",
  stepper_data_sources_2_step_name: "Basic Information",
  stepper_data_sources_2_basic_information_header: "Basic Information",
  stepper_data_sources_2_name_label: "Name",
  stepper_data_sources_2_name_tooltip: null,
  stepper_data_sources_2_name_placeholder: null,
  stepper_data_sources_2_configuration_header: "Configuration",
  stepper_data_sources_2_crawl_root_urls_label: "Root URLs",
  stepper_data_sources_2_crawl_root_urls_tooltip:
    "Comma-separated URLs to crawl (e.g. https://www.google.com,https://www.medium.com).",
  stepper_data_sources_2_crawl_root_urls_placeholder:
    "https://www.google.com,https://www.medium.com ...",
  stepper_data_sources_2_bq_project_label: "BQ Project",
  stepper_data_sources_2_bq_project_tooltip: "BigQuery Project",
  stepper_data_sources_2_bq_project_placeholder: null,
  stepper_data_sources_2_bq_dataset_label: "BQ Dataset",
  stepper_data_sources_2_bq_dataset_tooltip:
    "Dataset within the selected BQ Project",
  stepper_data_sources_2_bq_dataset_placeholder: null,
  stepper_data_sources_2_merge_filestorage_drives_label: "Drives",
  stepper_data_sources_2_merge_filestorage_folders_label: "Folders",
  stepper_data_sources_2_merge_ticketing_collections_label:
    "Collections (Projects)",
  stepper_data_sources_2_mongo_host_label: "Host",
  stepper_data_sources_2_mongo_host_tooltip: null,
  stepper_data_sources_2_mongo_host_placeholder: null,
  stepper_data_sources_2_mongo_port_label: "Port",
  stepper_data_sources_2_mongo_port_tooltip: null,
  stepper_data_sources_2_mongo_port_placeholder: null,
  stepper_data_sources_2_mongo_username_label: "Username",
  stepper_data_sources_2_mongo_username_tooltip: null,
  stepper_data_sources_2_mongo_username_placeholder: null,
  stepper_data_sources_2_mongo_password_label: "Password",
  stepper_data_sources_2_mongo_password_tooltip: null,
  stepper_data_sources_2_mongo_password_placeholder: null,
  stepper_data_sources_2_mongo_database_label: "Database",
  stepper_data_sources_2_mongo_database_tooltip: null,
  stepper_data_sources_2_mongo_database_placeholder: null,
  stepper_data_sources_2_mongo_auth_source_label: "Auth. Database",
  stepper_data_sources_2_mongo_auth_source_tooltip:
    "Database to use for authenticating the user",
  stepper_data_sources_2_mongo_auth_source_placeholder: "admin",
  stepper_data_sources_2_mongo_ssl_label: "Use SSL",
  stepper_data_sources_2_mongo_ssl_tooltip:
    "Whether to use SSL as part of authentication to the MongoDB Server",
  stepper_data_sources_2_mysql_host_label: "Host",
  stepper_data_sources_2_mysql_host_tooltip: null,
  stepper_data_sources_2_mysql_host_placeholder: null,
  stepper_data_sources_2_mysql_port_label: "Port",
  stepper_data_sources_2_mysql_port_tooltip: null,
  stepper_data_sources_2_mysql_port_placeholder: null,
  stepper_data_sources_2_mysql_username_label: "Username",
  stepper_data_sources_2_mysql_username_tooltip: null,
  stepper_data_sources_2_mysql_username_placeholder: null,
  stepper_data_sources_2_mysql_password_label: "Password",
  stepper_data_sources_2_mysql_password_tooltip: null,
  stepper_data_sources_2_mysql_password_placeholder: null,
  stepper_data_sources_2_mysql_database_label: "Database",
  stepper_data_sources_2_mysql_database_tooltip: null,
  stepper_data_sources_2_mysql_database_placeholder: null,
  stepper_data_sources_2_postgres_host_label: "Host",
  stepper_data_sources_2_postgres_host_tooltip: null,
  stepper_data_sources_2_postgres_host_placeholder: null,
  stepper_data_sources_2_postgres_port_label: "Port",
  stepper_data_sources_2_postgres_port_tooltip: null,
  stepper_data_sources_2_postgres_port_placeholder: null,
  stepper_data_sources_2_postgres_username_label: "Username",
  stepper_data_sources_2_postgres_username_tooltip: null,
  stepper_data_sources_2_postgres_username_placeholder: null,
  stepper_data_sources_2_postgres_password_label: "Password",
  stepper_data_sources_2_postgres_password_tooltip: null,
  stepper_data_sources_2_postgres_password_placeholder: null,
  stepper_data_sources_2_postgres_database_label: "Database",
  stepper_data_sources_2_postgres_database_tooltip: null,
  stepper_data_sources_2_postgres_database_placeholder: null,
  stepper_data_sources_2_postgres_db_schema_label: "DB Schema",
  stepper_data_sources_2_postgres_db_schema_tooltip:
    "The schema within the DB to read from",
  stepper_data_sources_2_postgres_db_schema_placeholder: "public",
  stepper_data_sources_2_postgres_ssh_tunnel_label: "Use SSH tunneling",
  stepper_data_sources_2_postgres_ssh_tunnel_tooltip: "Use SSH tunneling",
  stepper_data_sources_2_postgres_ssh_host_label: "Host",
  stepper_data_sources_2_postgres_ssh_host_tooltip:
    "Please ensure 100.21.84.250 is whitelisted in the host's security groups",
  stepper_data_sources_2_postgres_ssh_host_placeholder: "host",
  stepper_data_sources_2_postgres_ssh_username_label: "Username",
  stepper_data_sources_2_postgres_ssh_username_tooltip:
    "The username to connect to the bastion host",
  stepper_data_sources_2_postgres_ssh_username_placeholder: "ec2-user",
  stepper_data_sources_2_postgres_ssh_password_label: "Password",
  stepper_data_sources_2_postgres_ssh_password_tooltip:
    "Enable PasswordAuthentication in /etc/ssh/sshd_config for user and restart the sshd service",
  stepper_data_sources_2_postgres_ssh_password_placeholder: "password",
  stepper_data_sources_2_snowflake_account_label:
    "Snowflake Account Identifier",
  stepper_data_sources_2_snowflake_account_tooltip:
    "Snowflake Account Identifier (Do *not* include snowflakecomputing.com)",
  stepper_data_sources_2_snowflake_account_placeholder: null,
  stepper_data_sources_2_snowflake_user_label: "Snowflake Username",
  stepper_data_sources_2_snowflake_user_tooltip: null,
  stepper_data_sources_2_snowflake_user_placeholder: null,
  stepper_data_sources_2_snowflake_password_label: "Snowflake Account Password",
  stepper_data_sources_2_snowflake_password_tooltip:
    "The password will be kept encrypted and safe",
  stepper_data_sources_2_snowflake_password_placeholder: null,
  stepper_data_sources_2_snowflake_database_label: "Snowflake Database",
  stepper_data_sources_2_snowflake_database_tooltip:
    "The database to connect to",
  stepper_data_sources_2_snowflake_database_placeholder: null,
  stepper_data_sources_2_snowflake_schema_label: "Schema within the Database",
  stepper_data_sources_2_snowflake_schema_tooltip:
    "The schema to connect to within the target database",
  stepper_data_sources_2_snowflake_schema_placeholder: "public",
  stepper_data_sources_2_snowflake_warehouse_label: "Snowflake Query Warehouse",
  stepper_data_sources_2_snowflake_warehouse_tooltip:
    "The warehouse to use for running the query",
  stepper_data_sources_2_snowflake_warehouse_placeholder: null,
  stepper_data_sources_2_snowflake_role_label: "Snowflake Role",
  stepper_data_sources_2_snowflake_role_tooltip:
    "The role to use for running the query",
  stepper_data_sources_2_snowflake_role_placeholder: null,
  stepper_data_sources_2_delta_share_credentials_version_label:
    "Credentials Version",
  stepper_data_sources_2_delta_share_credentials_version_tooltip:
    "The credentials version provided in the credentials file",
  stepper_data_sources_2_delta_share_credentials_version_placeholder: null,
  stepper_data_sources_2_delta_share_bearer_token_label: "Bearer Token",
  stepper_data_sources_2_delta_share_bearer_token_tooltip:
    "The bearer token provided in the credentials file",
  stepper_data_sources_2_delta_share_bearer_token_placeholder:
    "<bearer token from credentials file>",
  stepper_data_sources_2_delta_share_endpoint_label: "Delta Share Endpoint",
  stepper_data_sources_2_delta_share_endpoint_tooltip:
    "The endpoint provided in the credentials file",
  stepper_data_sources_2_delta_share_endpoint_placeholder:
    "<endpoint> from credentials file>",
  stepper_data_sources_2_delta_share_expiration_label: "Share Expiration",
  stepper_data_sources_2_delta_share_expiration_tooltip:
    "The expiration time provided in the credentials file",
  stepper_data_sources_2_delta_share_expiration_placeholder:
    "YYYY-MM-DDTH:M:S:msZ",
  stepper_data_sources_2_jira_server_label: "Server",
  stepper_data_sources_2_jira_server_tooltip: null,
  stepper_data_sources_2_jira_server_placeholder: null,
  stepper_data_sources_2_jira_username_label: "Username",
  stepper_data_sources_2_jira_username_tooltip: null,
  stepper_data_sources_2_jira_username_placeholder: null,
  stepper_data_sources_2_jira_token_label: "Security Token",
  stepper_data_sources_2_jira_token_tooltip: null,
  stepper_data_sources_2_jira_token_placeholder: null,
  stepper_data_sources_2_confluence_spaces_label: "Spaces",
  stepper_data_sources_2_confluence_spaces_tooltip: null,
  stepper_data_sources_2_confluence_spaces_placeholder: null,
  stepper_data_sources_2_confluence_limit_label: "Limit",
  stepper_data_sources_2_confluence_limit_tooltip:
    "Maximum pages to retrieve per run.",
  stepper_data_sources_2_confluence_limit_placeholder: null,
  stepper_data_sources_2_github_repos_label: "Repositories",
  stepper_data_sources_2_github_repos_tooltip:
    "GitHub repositories to ingest data from.",
  stepper_data_sources_2_github_repos_placeholder: null,
  stepper_data_sources_2_linkedin_login_label: "Login",
  stepper_data_sources_2_linkedin_login_tooltip: null,
  stepper_data_sources_2_linkedin_login_placeholder: null,
  stepper_data_sources_2_linkedin_password_label: "Password",
  stepper_data_sources_2_linkedin_password_tooltip: null,
  stepper_data_sources_2_linkedin_password_placeholder: null,
  stepper_data_sources_2_linkedin_keywords_label: "Keywords",
  stepper_data_sources_2_linkedin_keywords_tooltip: null,
  stepper_data_sources_2_linkedin_keywords_placeholder: null,
  stepper_data_sources_2_linkedin_current_company_label: "Current Company",
  stepper_data_sources_2_linkedin_current_company_tooltip: null,
  stepper_data_sources_2_linkedin_current_company_placeholder: null,
  stepper_data_sources_2_linkedin_past_companies_label: "Past Companies",
  stepper_data_sources_2_linkedin_past_companies_tooltip: null,
  stepper_data_sources_2_linkedin_past_companies_placeholder: null,
  stepper_data_sources_2_linkedin_regions_label: "Regions",
  stepper_data_sources_2_linkedin_regions_tooltip: null,
  stepper_data_sources_2_linkedin_regions_placeholder: null,
  stepper_data_sources_2_linkedin_schools_label: "Schools",
  stepper_data_sources_2_linkedin_schools_tooltip: null,
  stepper_data_sources_2_linkedin_schools_placeholder: null,
  stepper_data_sources_2_linkedin_industries_label: "Industries",
  stepper_data_sources_2_linkedin_industries_tooltip: null,
  stepper_data_sources_2_linkedin_industries_placeholder: null,
  stepper_data_sources_2_linkedin_limit_label: "Limit",
  stepper_data_sources_2_linkedin_limit_tooltip:
    "Maximum pages to retrieve per run.",
  stepper_data_sources_2_linkedin_limit_placeholder: null,
  stepper_data_sources_2_slack_channels_label: "Channels",
  stepper_data_sources_2_slack_channels_tooltip:
    "At least one channel is required.",
  stepper_data_sources_2_slack_channels_placeholder: "general, random, ...",
  stepper_data_sources_2_slack_user_label: "Users",
  stepper_data_sources_2_slack_user_tooltip: "Messages sent by this user.",
  stepper_data_sources_2_slack_user_placeholder: null,
  stepper_data_sources_2_slack_search_label: "Search Text",
  stepper_data_sources_2_slack_search_tooltip: "Messages containing this text.",
  stepper_data_sources_2_slack_search_placeholder: null,
  stepper_data_sources_2_slack_origin_time_label:
    "Starting from what date should we retrieve messages?",
  stepper_data_sources_2_slack_origin_time_tooltip:
    "We will only ingest messages that were posted after specified date.",
  stepper_data_sources_2_slack_origin_time_placeholder: null,
  stepper_data_sources_2_wiki_search_label: "Search Query",
  stepper_data_sources_2_wiki_search_tooltip: null,
  stepper_data_sources_2_wiki_search_placeholder: null,
  stepper_data_sources_2_wiki_limit_label: "Limit",
  stepper_data_sources_2_wiki_limit_tooltip:
    "Maximum pages to retrieve per run.",
  stepper_data_sources_2_wiki_limit_placeholder: null,
  stepper_data_sources_3_step_name: "Configure Schema",
  stepper_data_sources_4_step_name: "Preview",

  title_something_went_wrong: "Oops! Something went wrong.",
  graft_is_down:
    "Graft is temporarily down. You can contact us at support@graft.com",
  graft_is_having_issues:
    "Graft is temporarily experiencing hiccups. You can contact us at support@graft.com",
  network_connection_error:
    "Unable to connect to Graft. Please check your network connection and try again",
  user_not_in_organization: "The user is not registered with the organization.",
};
