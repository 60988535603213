// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { initGraftUser } from "../../gql/local/GraftUserVar";
import { useShiftEscClearDevOverlay } from "../../hooks/useShiftEscClearDevOverlay";
import { useGraftAnalytics } from "../../services/analytics/useGraftAnalytics";
import { setMuiLicense } from "../../services/muiPro/muiPro";
import { setSentryUser } from "../../services/sentry/sentry";
import { useLegacyLanding } from "../useLegacyLanding";
import AppRouter from "./AppRouter";

setMuiLicense();

export default function AppContainer() {
  const legacyLanding = useLegacyLanding();
  const { user } = useAuth0();
  const location = useLocation();
  const analytics = useGraftAnalytics();
  useShiftEscClearDevOverlay();

  useEffect(() => {
    if (user) {
      initGraftUser(user);
      setSentryUser(user);
    }
  }, [user]);

  useEffect(() => {
    analytics.page();
  }, [location.pathname]);

  if (legacyLanding) {
    return null;
  }
  return <AppRouter />;
}
