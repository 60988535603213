// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiTabs(typography: GraftTypographyOptions) {
  return {
    root: {
      ...typography.caption,
      textTransform: "none" as const,
      "&.Mui-selected": {
        fontWeight: 500,
      },
    },
  };
}
