// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import {
  DotsThreeCircle,
  Question,
  WarningCircle,
} from "@phosphor-icons/react";
import { GraftIdProps, GraftStatusProps } from "../../types/GraftProps";
import { GraftTooltip } from "../tooltip/GraftTooltip";

export type GraftStatusTooltipProps = GraftIdProps & GraftStatusProps;

export function GraftStatusTooltip({
  id,
  graftTestId,
  disabled,
  loading,
  error,
  info,
}: GraftStatusTooltipProps) {
  const Icon = getIcon(disabled, loading, error, info);
  if (Icon == null) {
    return null;
  }
  const status = disabled || loading || error || info;
  if (status == null || status === "" || status === true) {
    return null;
  }
  return (
    <GraftTooltip
      disabled={disabled}
      loading={loading}
      error={error}
      info={info}
    >
      <Icon id={id} data-testid={graftTestId} />
    </GraftTooltip>
  );
}

function getIcon(
  disabled: GraftStatusProps["disabled"],
  loading: GraftStatusProps["loading"],
  error: GraftStatusProps["error"],
  info: GraftStatusProps["info"],
) {
  return disabled
    ? Question
    : loading
      ? DotsThreeCircle
      : error
        ? WarningCircle
        : info
          ? Question
          : null;
}
