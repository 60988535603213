// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";

export function muiLinearProgress(palette: GraftPaletteOptions) {
  return {
    root: {
      "&.MuiLinearProgress-determinate": {
        height: "16px",
        borderRadius: "4px",
        border: "1px solid",
        backgroundColor: palette.secondary.lighter,
        borderColor: palette.secondary.main,
      },
    },
    bar: {
      borderRadius: "4px",
      backgroundColor: palette.secondary.darker,
    },
  };
}
