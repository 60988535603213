// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";

export function useGraftNavigate() {
  const navigate = useNavigate();
  return (
    path: string | number,
    event: MouseEvent | null = null,
    state: object | null = null,
  ) => {
    if (typeof path === "number") {
      return navigate(path);
    }
    if (event && location.hostname === "localhost") {
      // Allows for local alt-shift click of table item to navigate to *_wip parent route for wip dev
      const navParts = path.split("/");
      const useDevWipNavLinkFormat =
        event.shiftKey &&
        event.altKey &&
        navParts.length === 2 &&
        navParts[0] === ".";

      if (useDevWipNavLinkFormat) {
        const pathParts = location.href.split("/");
        const parentSeg = pathParts[pathParts.length - 1];
        const devWipNav = `../${parentSeg}${
          parentSeg.includes("_wip") ? "" : "_wip"
        }`;
        return navigate(`${devWipNav}/${path}`, state ?? undefined);
      }
    }
    return navigate(path, state ?? undefined);
  };
}
