// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";

export function muiSpeedDialAction(palette: GraftPaletteOptions) {
  return {
    fab: {
      "&:hover": {
        backgroundColor: palette.common.white,
      },
    },
  };
}
