// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiRadio(
  palette: GraftPaletteOptions,
  __: GraftTypographyOptions,
) {
  return {
    root: {
      "&:not(.Mui-disabled)": {
        "& .MuiSvgIcon-root:not(.MuiSvgIcon-root ~ .MuiSvgIcon-root)": {
          color: palette.action.active,
        },
        "& .MuiSvgIcon-root + .MuiSvgIcon-root": {
          color: palette.action.selected,
        },
      },
    },
  };
}
