// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiMenuItem(
  palette: GraftPaletteOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      ...typography.body1,
      "&:hover": {
        backgroundColor: palette.secondary.lighter,
      },
      '&[aria-selected="true"], &.Mui-focusVisible': {
        backgroundColor: palette.primary.lighter,
      },
    },
  };
}
