// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { stringifyGraftError } from "../helpers/graftError";
import { uniqueId } from "../helpers/uniqueId";

/**
 * Graft wrapper over browser fetch, which includes graft tracking headers,
 * graftifies errors and throws for certain error codes,
 */
export const graftFetch: WindowOrWorkerGlobalScope["fetch"] = (
  uri,
  options = {},
) => {
  const request_id = `CR_${uniqueId()}`;
  const extendedOptions = {
    ...options,
    headers: {
      "X-Graft-Page-Load-Id": PAGE_LOAD_ID,
      "X-Graft-Request-Id": request_id,
      ...options?.headers,
    },
  };
  return fetch(uri, extendedOptions)
    .catch((e) => {
      if (e.message == "Failed to fetch") {
        throw Error(
          stringifyGraftError({
            title: "$title_something_went_wrong",
            message: "$network_connection_error",
            code: "FAILED_TO_FETCH",
            request_id,
          }),
        );
      } else {
        throw Error(
          stringifyGraftError({
            title: "$title_something_went_wrong",
            message: "$graft_is_having_issues",
            code: "FETCH_EXCEPTION",
            request_id,
            details: String(e),
          }),
        );
      }
    })
    .then((response) => {
      return throwForStatus(response, request_id).then(() => response);
    });
};

/**
 * Throw graftifies errors for non-success http responses.
 */
async function throwForStatus(response: Response, request_id: string) {
  const { status } = response;
  if (status < 200 || status > 299) {
    const text = await response.text();
    const fastApiError = maybeGetFastApiError(text);
    if (fastApiError) {
      // if we have an error returned by FastAPI, just throw that
      throw Error(fastApiError);
    }
    // TODO: handle FastAPI errors returned as JSON.
    const trimmedText = text.substring(0, 1000);
    const message =
      status == 502 || status == 503 || status == 504
        ? "$graft_is_down"
        : "$graft_is_having_issues";
    throw Error(
      stringifyGraftError({
        title: "$title_something_went_wrong",
        message,
        code: `CLIENT_HTTP_${status}`,
        request_id,
        details: trimmedText,
      }),
    );
  }
}

/**
 * FastAPI/starlette return JSON with the error message being in "detail" field.
 */
function maybeGetFastApiError(text: string): string | undefined {
  try {
    const parsed = JSON.parse(text);
    const detail = parsed?.detail;
    if (detail && typeof detail === "string") {
      return detail;
    }
  } catch (e) {
    // nothing
  }
}

const PAGE_LOAD_ID = `PL_${uniqueId()}`;
