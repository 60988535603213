// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ContentRecord } from "../content";

export const JAJP_CONTENT: ContentRecord = {
  page_data_title: "データ",
  page_data_subtitle: "資格情報、データソース、およびエンティティを管理する。",
  page_data_sources_subtitle:
    "あなたが作業したいデータに接続する方法。例えば、Slackアカウント、JIRAチケット、またはSQLデータベースなどがあります。",
  page_data_sources_title: "データソース",
  page_enrichments_subtitle:
    "すべてのエンティティのエンリッチメントは以下に表示されます。作業したいものをクリックしてください。",
  page_enrichments_title: "豊かさ",
  page_entities_subtitle:
    "作業したいオブジェクトを表すデータの集合。例えば、ブログ投稿は画像とテキストから成るエンティティです。",
  page_entities_title: "エンティティ",
  page_entity_details_title: "エンティティの詳細",
  page_jobs_title: "仕事",
  page_monitoring_title: "監視 (Kanshi)",
  page_projects_title: "プロジェクト",

  graft_is_down:
    "グラフトは一時的にダウンしています。お問い合わせはsupport@graft.comまでお願いします。",
  graft_is_having_issues:
    "グラフトは一時的にヒックアップを経験しています。お問い合わせはsupport@graft.comまでお願いします。",
  title_something_went_wrong: "おっと！何かがうまくいきませんでした。",
  user_not_in_organization: "ユーザーは組織に登録されていません。",
};
