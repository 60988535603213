// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ContentRecord } from "../content";

export const AREG_CONTENT: ContentRecord = {
  page_data_title: "البيانات",
  page_data_subtitle: "إدارة بيانات الاعتمادات، مصادر البيانات، والكيانات",
  page_data_sources_subtitle:
    "طريقة للاتصال بالبيانات التي تريد العمل معها. على سبيل المثال، حساب سلاك، تذاكر جيرا، أو قاعدة بيانات SQL.",
  page_data_sources_title: "مصادر البيانات",
  page_enrichments_subtitle:
    "التحسينات لجميع الكيانات معروضة أدناه، يرجى النقر على تلك التي ترغب في العمل عليها.",
  page_enrichments_title: "تحسينات",
  page_entities_subtitle:
    "مجموعة من البيانات التي تمثل كائنًا ترغب في العمل معه. على سبيل المثال ، مقالة في المدونة هي كيان يتكون من صور ونصوص.",
  page_entities_title: "الكيانات",
  page_entity_details_title: "تفاصيل الكيان",
  page_jobs_title: "وظائف",
  page_monitoring_title: "المراقبة",
  page_projects_title: "مشاريع",

  graft_is_down:
    "الغرس متوقف مؤقتاً. يمكنك التواصل معنا على البريد الإلكتروني support@graft.com",
  graft_is_having_issues:
    "الغرسة تعاني حالياً من بعض الصعوبات المؤقتة. يمكنك التواصل معنا على البريد الإلكتروني support@graft.com",
  title_something_went_wrong: "عفواً! حدث خطأ ما.",
  user_not_in_organization: "المستخدم غير مسجل مع المنظمة.",
};
