// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiDialog(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      "& .MuiDialogActions-root": {
        padding: 0,
      },
      "& .MuiDialogContent-root": {
        padding: 0,
      },
      "& .MuiDialogTitle-root": {
        ...typography.h2,
        textAlign: "center",
      },
    },
  };
}
