// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { CSSPropertiesWithMultiValues } from "@mui/styled-engine";
import { GraftTypographyOptions } from "../../types/GraftTypographyOptions";

export function createGraftGlobalStyles(
  typography: GraftTypographyOptions,
): CSSPropertiesWithMultiValues {
  // NOTE(eschkufz): This horrific styling is used to identify places where naked mui components or html elements are
  //  used outside GraftComponent wrappers. If you see this awful styling somewhere, it indicates that you're
  //  likely violating a design system rule.
  const awful = {
    background:
      "linear-gradient(to right, #ef5350, #f48fb1, #7e57c2, #2196f3, #26c6da, #43a047, #eeff41, #f9a825, #ff5722)",
  };

  return {
    "*": {
      boxSizing: "border-box",
    },

    // Don't use raw html weight tags. And these decorations aren't in the Graft design system anyway.
    i: awful,

    // Technically you shouldn't be using these either, but 9 times out of 10 you're using GraftTypography's default
    //   weight (regular) and you want something to stand out, all you're going to do is set weight to "medium". So...
    //   fine... This definition makes
    //     <GraftTypography>Hello{" "}</GraftTypography> <GraftTypography variant="medium">world</GraftTypography>
    //   the same as
    //     <GraftTypography>Hello <b>world</b></GraftTypography>
    b: { fontWeight: typography.weight.semiBold },
    strong: { fontWeight: typography.weight.semiBold },

    // These font variants are unused in Graft's design system.
    subtitle1: awful,
    subtitle2: awful,
  } as CSSPropertiesWithMultiValues;
}
