// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
export function muiCardContent() {
  return {
    root: {
      padding: "2rem",
      "&:last-child": {
        padding: "2rem",
      },
    },
  };
}
