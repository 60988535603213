// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { lazy } from "react";
import { Outlet, Route, Routes } from "react-router-dom";
import { GraftErrorBoundary } from "../../components/error/GraftErrorBoundary";
import { GraftSuspense } from "../../components/suspense/GraftSuspense";
import { Redirect } from "../../helpers/Redirect";
import { StyleProvider } from "../../styles/StyleProvider";
import { InviteLink } from "../InviteLink";
import { ROOT_ROUTES } from "../../containers/RootRoutes";

// Using lazy for code splitting, see: https://reactjs.org/docs/code-splitting.html
const Layout = lazy(() => import("../../containers/site/Layout"));
const RequiresAuthentication = lazy(() => import("../RequiresAuthentication"));
const SlackOAuth = lazy(() => import("../SlackOAuth"));
const AtlassianOAuth = lazy(() => import("../AtlassianOAuth"));
const GitHubOAuth = lazy(() => import("../GitHubOAuth"));

const Summary = lazy(() => import("../../containers/projects/Summary"));
const Router = lazy(() => import("../../containers/projects/Router"));
const RootContainer = lazy(() => import("../../containers/RootContainer"));

export default function AppRouter() {
  return (
    <GraftErrorBoundary location="App Router" autoReset>
      <Routes>
        <Route index element={<Redirect path="/projects" />} />
        <Route path="landing">
          <Route index element={<Redirect path="/projects" />} />
          <Route path="invite" element={<InviteLink />} />
        </Route>
        <Route
          element={
            <GraftSuspense fallback={null}>
              <StyleProvider>
                <RequiresAuthentication>
                  <Layout>
                    <GraftSuspense>
                      <Outlet />
                    </GraftSuspense>
                  </Layout>
                </RequiresAuthentication>
              </StyleProvider>
            </GraftSuspense>
          }
        >
          <Route path="landing">
            <Route path="slack" element={<SlackOAuth />} />
            <Route path="atlassian" element={<AtlassianOAuth />} />
            <Route path="github" element={<GitHubOAuth />} />
          </Route>
          <Route path="projects">
            <Route index element={<Summary />} />
            <Route path=":projectName/*" element={<Router />} />
          </Route>
        </Route>

        {/* Routes for Graft UI v3 */}
        <Route
          element={
            <GraftSuspense fallback={null}>
              <Outlet />
            </GraftSuspense>
          }
        >
          <Route path={`${ROOT_ROUTES.root}/*`} element={<RootContainer />} />
        </Route>

        <Route path="50x" element={<h2>An error occurred</h2>} />
        <Route path="*" element={<h2>Page not found</h2>} />
      </Routes>
    </GraftErrorBoundary>
  );
}
