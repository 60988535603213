// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiListItem(
  palette: GraftPaletteOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      "&.Mui-selected": {
        "& .MuiListItemText-primary": {
          ...typography.caption,
          textTransform: "uppercase" as const,
          color: palette.primary.dark,
        },
        "& .MuiListItemText-secondary": {
          ...typography.body1,
          color: palette.primary.darker,
        },
      },
      "&:not(.Mui-selected)": {
        "& .MuiListItemText-primary": {
          ...typography.caption,
          textTransform: "uppercase" as const,
          color: palette.secondary.dark,
        },
        "& .MuiListItemText-secondary": {
          ...typography.body1,
          color: palette.secondary.darker,
        },
      },
      "&:hover": {
        backgroundColor: palette.secondary.lighter,
        cursor: "pointer",
      },
    },
  };
}
