// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { CSSProperties } from "react";
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiButton(
  palette: GraftPaletteOptions,
  typography: GraftTypographyOptions,
) {
  const commonHover: CSSProperties = {
    backgroundColor: "transparent",
    textDecoration: "underline",
    textUnderlineOffset: 6,
  };

  const commonDisabled = {
    color: palette.secondary.main,
    borderColor: palette.secondary.main,
    backgroundColor: palette.action.disabledBackground,
  };
  const commonSizes = {
    "&.MuiButton-sizeSmall": {
      ...typography.overline,
      height: 30,
      width: 120,
    },
    "&.MuiButton-sizeMedium": {
      ...typography.button,
      height: 40,
      width: 210,
    },
    "&.MuiButton-sizeLarge": {
      ...typography.button,
      height: "fit-content",
      width: "fit-content",
    },
  };

  return {
    root: {
      "&.MuiButton-textInherit": {
        // TODO...
      },
      "&.MuiButton-textPrimary": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.common.blue,
        },
        ...commonSizes,
      },
      "&.MuiButton-textSecondary": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.secondary.darker,
        },
        ...commonSizes,
      },
      "&.MuiButton-textSuccess": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.success.main,
        },
        ...commonSizes,
      },
      "&.MuiButton-textError": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.error.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-textInfo": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.info.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-textWarning": {
        border: "none",
        "&:hover": commonHover,
        "&:disabled": { color: palette.secondary.main },
        "&:not(:disabled)": {
          color: palette.warning.dark,
        },
        ...commonSizes,
      },

      "&.MuiButton-outlinedInherit": {
        // TODO...
      },
      "&.MuiButton-outlinedPrimary": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.common.black,
          color: palette.common.black,
        },
        ...commonSizes,
      },
      "&.MuiButton-outlinedSecondary": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.secondary.dark,
          color: palette.secondary.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-outlinedSuccess": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.success.dark,
          color: palette.success.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-outlinedError": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.error.dark,
          color: palette.error.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-outlinedInfo": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.info.dark,
          color: palette.info.dark,
        },
        ...commonSizes,
      },
      "&.MuiButton-outlinedWarning": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.warning.dark,
          color: palette.warning.dark,
        },
        ...commonSizes,
      },

      "&.MuiButton-containedInherit": {
        // TODO...
      },
      "&.MuiButton-containedPrimary": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.black,
          borderColor: palette.common.black,
          color: palette.common.white,
        },
        ...commonSizes,
      },
      "&.MuiButton-containedSecondary": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.common.white,
          borderColor: palette.common.white,
          color: palette.secondary.darker,
        },
        ...commonSizes,
      },
      "&.MuiButton-containedSuccess": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.success.main,
          borderColor: palette.success.main,
          color: palette.success.contrastText,
        },
        ...commonSizes,
      },
      "&.MuiButton-containedError": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.error.main,
          borderColor: palette.error.main,
          color: palette.error.contrastText,
        },
        ...commonSizes,
      },
      "&.MuiButton-containedInfo": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.info.main,
          borderColor: palette.info.main,
          color: palette.info.contrastText,
        },
        ...commonSizes,
      },
      "&.MuiButton-containedWarning": {
        "&:hover": commonHover,
        "&:disabled": commonDisabled,
        "&:not(:disabled)": {
          backgroundColor: palette.warning.main,
          borderColor: palette.warning.main,
          color: palette.warning.contrastText,
        },
        ...commonSizes,
      },

      borderRadius: "1.25rem",
      border: "solid",
      borderWidth: "1px",
    },
  };
}
