// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import {
  AppWindow,
  Atom,
  Eye,
  HighlighterCircle,
  Question,
  Record,
  Rows,
  SealQuestion,
  Sidebar,
  SubtractSquare,
} from "@phosphor-icons/react";
import { Layout } from "react-grid-layout";
import { LocalStorage } from "../../helpers/LocalStorage";
import { ProjectSchema } from "../../helpers/project";
import { cloneDeep, objectReduce } from "../../helpers/utility";
import { GraftUserVar } from "../local/GraftUserVar";

enum EnvLevel {
  ALL,
  DEVELOP,
  STAGING,
  PROD,
}

type ProjectDashboardLayout = Record<ProjectSchema["name"], Layout[]>;

type TooltipMap = { [key: string]: number };

type ValidSettingsValueTypes =
  | boolean
  | string
  | ProjectDashboardLayout
  | TooltipMap
  | number;

type UserSetting<T extends ValidSettingsValueTypes> = {
  envLevel: EnvLevel;
  graftOnly: boolean;
  value: T;
  disabled?: boolean;
  label?: string;
  hidden?: boolean;
  subtitle: string;
  icon?: JSX.Element;
  toggleText?: [string, string];
};

const defaultSettingValues: Omit<
  UserSetting<ValidSettingsValueTypes>,
  "value" | "label" | "toggleText"
> = {
  envLevel: EnvLevel.ALL,
  disabled: false,
  graftOnly: false,
  hidden: false,
  subtitle: "",
};

/**
 * Available user settings. Add new settings here.
 * Eventually will be refactored to use persisted BE user data.
 *
 * NOTE: "helpTooltips" is temporarily disabled until we have
 * a better way to manage the user tips (possibly via/with Pendo).
 *
 * Initially the plan was to have a specific GraftHelpTooltip or equivalent.
 * But, the tooltip has become prolific since then and this was rolled in,
 * requiring a setting check every render of every GraftStatusTooltip.
 * Decent cost, low benefit as it's possibly confusing UX if disabled.
 */

interface UserSettings {
  viewAsNonGraftUser: UserSetting<boolean>;
  minimizedSideNav: UserSetting<boolean>;
  advancedTabs: UserSetting<boolean>;
  helpTooltips: UserSetting<boolean>;
  defaultPage: UserSetting<string>;
  dashboardLayout: UserSetting<ProjectDashboardLayout>;
  lastLogin: UserSetting<number>;
  hasSnippetCitations: UserSetting<boolean>;
  hasChatSuggestions: UserSetting<boolean>;
  hasChatPeekBehind: UserSetting<boolean>;
  isRawChatFormat: UserSetting<boolean>;
  localization: UserSetting<string>;
  tooltip: UserSetting<TooltipMap>;
}

export type UserSettingsKey = keyof UserSettings;

const defaultDashboardLayout: Layout[] = [
  { i: "A", x: 0, y: 0, w: 1, h: 1 },
  { i: "B", x: 1, y: 0, w: 1, h: 1 },
  { i: "C", x: 2, y: 0, w: 1, h: 1 },
  { i: "D", x: 0, y: 1, w: 1, h: 1 },
  { i: "E", x: 1, y: 1, w: 1, h: 1 },
  { i: "F", x: 2, y: 1, w: 1, h: 1 },
  { i: "G", x: 3, y: 0, w: 2.5, h: 3 },
];
const defaultUserSettings: UserSettings = {
  viewAsNonGraftUser: {
    ...defaultSettingValues,
    graftOnly: true,
    label: "View as customer",
    value: false,
    subtitle: "View Graft as a customer",
    icon: <Eye />,
    toggleText: ["Customer", "Employee"],
  },
  advancedTabs: {
    ...defaultSettingValues,
    label: "Advanced Tabs",
    value: false,
    subtitle: "Show advanced options in the top-level menu",
    icon: <Atom />,
    toggleText: ["Showing", "Hiding"],
  },
  minimizedSideNav: {
    ...defaultSettingValues,
    label: "Minimize Sidebar",
    value: false,
    subtitle: "Use a smaller top-level menu to save screen space",
    icon: <Sidebar />,
    toggleText: ["Minimized", "Full-sized"],
  },
  defaultPage: {
    ...defaultSettingValues,
    label: "Landing Page",
    value: "apps",
    graftOnly: true,
    subtitle: "The page which is loaded when you log in to Graft",
    icon: <AppWindow />,
  },
  localization: {
    ...defaultSettingValues,
    label: "Language",
    value: "en-us",
    graftOnly: true,
    subtitle: "Change language settings",
  },
  helpTooltips: {
    ...defaultSettingValues,
    label: "Help Tooltips",
    value: true,
    graftOnly: true,
    subtitle: "Enable inline tooltips for graft features",
    icon: <Question />,
    toggleText: ["Enabled", "Disabled"],
  },
  hasSnippetCitations: {
    ...defaultSettingValues,
    label: "Use Snippet Citations",
    value: false,
    graftOnly: false,
    subtitle: "Enables the snippet format of Chat citations",
    icon: <Rows />,
  },
  hasChatSuggestions: {
    ...defaultSettingValues,
    label: "Show Chat Suggestions",
    value: false,
    graftOnly: false,
    subtitle: "Enables the use of suggestions with new chats",
    icon: <SealQuestion />,
  },
  hasChatPeekBehind: {
    ...defaultSettingValues,
    label: "Show Chat Peek Behind",
    value: false,
    graftOnly: true,
    subtitle: "Enables button for peek behind details",
    icon: <SubtractSquare />,
  },
  isRawChatFormat: {
    ...defaultSettingValues,
    label: "Show Raw Chat Format",
    value: false,
    graftOnly: true,
    subtitle: "Disables the Markdown formatting in Chat",
    icon: <HighlighterCircle />,
  },
  dashboardLayout: {
    ...defaultSettingValues,
    hidden: true,
    graftOnly: true,
    value: { default: defaultDashboardLayout },
  },
  lastLogin: {
    ...defaultSettingValues,
    hidden: true,
    value: Date.now(),
  },
  tooltip: {
    ...defaultSettingValues,
    label: "Tooltip",
    hidden: true,
    graftOnly: false,
    value: {},
  },
};

// Todo: Needs to be strongly typed
const settingsMigrationMap = {
  dashboardLayout: {
    timestamp: 1688149304695,
    mFunction: (value: Layout[]) => {
      const layout = cloneDeep(value);
      if (Array.isArray(layout)) {
        return { default: layout };
      }
      return;
    },
  },
  defaultPage: {
    timestamp: 1688149304695,
    mFunction: (value: string) => {
      if (value === "dashboard") {
        return "apps";
      }
    },
  },
};

const userSettings: UserSettingsKey[] = Object.keys(
  defaultUserSettings,
) as UserSettingsKey[];

export class GraftUserSettings {
  private _settings: UserSettings;
  private readonly _storage: LocalStorage;
  private readonly _key: string;
  private readonly _isGraftEmployee: boolean;

  constructor(key: string, isGraftEmployee: boolean) {
    this._isGraftEmployee = isGraftEmployee;
    this._key = key;
    this._settings = cloneDeep(defaultUserSettings);
    if (key) {
      this._storage = new LocalStorage(key);
    }
    const storage = this._storage?.get();
    if (storage) {
      Object.entries(storage).forEach(
        ([key, value]: [
          key: UserSettingsKey,
          value: UserSettings[UserSettingsKey]["value"],
        ]) => {
          if (userSettings.includes(key)) {
            this._settings[key].value = value;
          } else {
            this.deleteSetting(key);
          }
        },
      );
    }
  }

  initialize = () => {
    this._safetyCheck();
    this._migrationCheck();
    this.updateSetting("lastLogin", Date.now());
  };

  private _storeSettings = () => {
    if (this._storage) {
      const storageSettings = Object.entries(this._settings).map(
        ([key, setting]: [
          UserSettingsKey,
          UserSetting<ValidSettingsValueTypes>,
        ]) => ({ [key]: setting.value }),
      );

      this._storage.set(storageSettings);
    }
  };

  private _safetyCheck = () => {
    const settings = cloneDeep(this._settings);
    //Verify no erroneous settings are persisted
    Object.keys(settings).forEach((key: UserSettingsKey) => {
      if (!userSettings.includes(key)) {
        this.deleteSetting(key);
      }
    });

    //Verify all settings are present
    userSettings.forEach((key) => {
      if (settings[key] == null) {
        (settings[key] as UserSettings[UserSettingsKey]) = cloneDeep(
          defaultUserSettings[key],
        );
      }
    });

    this._settings = settings;
    this._storeSettings();
  };

  private _migrationCheck = () => {
    Object.entries(settingsMigrationMap).forEach(
      ([key, { timestamp, mFunction }]) => {
        const setting = this._settings[key as UserSettingsKey];
        if (
          setting &&
          setting.value &&
          (!this._settings["lastLogin"] ||
            this._settings["lastLogin"].value < timestamp)
        ) {
          const newValue = mFunction(setting.value as any);
          if (newValue != null) {
            this.updateSetting(key as UserSettingsKey, newValue);
          }
        }
      },
    );
  };

  private deleteSetting = (key: string) => {
    const k = key as UserSettingsKey;
    if (this._settings[k]) {
      const storage = this._storage?.get();
      delete this._settings[k];
      if (storage) {
        delete storage[k];
        this._storage.set(storage);
      }
    }
  };

  getSetting = <T extends UserSettingsKey>(
    settingKey: T,
  ): UserSettings[T]["value"] => {
    const setting = this._settings[settingKey];
    return setting.disabled ||
      (setting.graftOnly &&
        (!this._isGraftEmployee || settingKey !== "viewAsNonGraftUser") &&
        this._settings["viewAsNonGraftUser"].value)
      ? defaultUserSettings[settingKey].value
      : setting.value;
  };

  getSettings = (): UserSettings => {
    return objectReduce<UserSettings, UserSettings>(
      this._settings,
      (acc, key, value) => {
        const isDisplayedSettings = !value.hidden && !value.disabled;
        const isGraftViewer =
          !this._settings["viewAsNonGraftUser"].value && this._isGraftEmployee;

        return {
          ...acc,
          ...(isDisplayedSettings &&
          (!value.graftOnly ||
            isGraftViewer ||
            (this._isGraftEmployee && key === "viewAsNonGraftUser"))
            ? {
                [key]: value,
              }
            : {}),
        };
      },
      {} as UserSettings,
    );
  };

  getSettingsList = () => {
    return objectReduce<
      UserSettings,
      [
        UserSettingsKey,
        UserSettings[UserSettingsKey]["value"],
        UserSettings[UserSettingsKey]["label"],
        UserSettings[UserSettingsKey]["subtitle"],
        UserSettings[UserSettingsKey]["icon"],
        UserSettings[UserSettingsKey]["graftOnly"],
        UserSettings[UserSettingsKey]["toggleText"],
      ][]
    >(
      this.getSettings(),
      (acc, key, { label, graftOnly, subtitle, icon, value, toggleText }) => [
        ...acc,
        [key, value, label, subtitle, icon, graftOnly, toggleText],
      ],
      [],
    );
  };

  /** This is type enforced, we shouldn't need a null check here (to avoid parse errors). Worth keeping an eye on. */
  updateSetting = <T extends UserSettingsKey>(
    settingKey: T,
    settingVal: UserSettings[T]["value"],
  ) => {
    this._settings = {
      ...this._settings,
      [settingKey]: {
        ...this._settings[settingKey],
        value: settingVal,
      },
    };
    this._storage.set(
      Object.entries(this._settings).reduce(
        (acc, [k, v]) => ({ ...acc, [k]: v.value }),
        {} as Record<T, UserSettings[T]["value"]>,
      ),
    );
    GraftUserVar({
      ...GraftUserVar(),
      settings: this,
    });
  };

  updateSettings = <T extends UserSettingsKey>(
    updatedSettings: Record<T, UserSettings[T]["value"]>[],
  ) => {
    updatedSettings.forEach((setting) => {
      const [settingKey, settingVal] = Object.entries(setting)[0] as [
        T,
        UserSettings[T]["value"],
      ];
      this.updateSetting(settingKey as T, settingVal);
    });
  };
}
