// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";

export function muiSvgIcon(palette: GraftPaletteOptions) {
  return {
    root: {
      "&.MuiSvgIcon-colorPrimary": {
        fill: palette.primary.main,
      },
      "&.MuiSvgIcon-colorSecondary": {
        fill: palette.secondary.main,
      },
      "&.MuiSvgIcon-colorError": {
        fill: palette.error.main,
      },
      "&.MuiSvgIcon-fontSizeSmall": {
        height: 16,
      },
      "&.MuiSvgIcon-fontSizeMedium": {
        height: 20,
      },
      "&.MuiSvgIcon-fontSizeLarge": {
        height: 24,
      },
    },
  };
}
