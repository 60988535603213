// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiTablePagination(typography: GraftTypographyOptions) {
  return {
    root: {
      "& p": {
        ...typography.body1,
      },
    },
  };
}
