// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Pendo = pendo.Pendo;
import { GraftUserProps, GraftUserVar } from "../../gql/local/GraftUserVar";
import { AnalyticsService } from "../analytics/types";
import { BasePendoEventProperties, PendoEventType } from "./PendoEvent";
import { PendoUser } from "./PendoUser";

import PendoMetadata = pendo.Metadata;

function getPendo(): Pendo {
  if (pendo == null) {
    throw Error("pendo script not loaded");
  }
  return pendo;
}

export class GraftPendo implements AnalyticsService {
  pendo: Pendo;
  user: PendoUser;

  constructor(user: GraftUserProps) {
    this.pendo = getPendo();
    this.user = new PendoUser(user);
  }

  track(eventType: PendoEventType, eventProperties: PendoMetadata) {
    const event = this.createPendoEvent(eventType, eventProperties);
    this.pendo.track(...event);
  }

  initialize() {
    this.user = new PendoUser(GraftUserVar());
    this.pendo.initialize(this.formatPendoIdentityFromUser());
  }

  page() {
    // noop
  }

  private createPendoEvent = <T extends PendoMetadata>(
    eventType: PendoEventType,
    eventProperties: T,
  ): [BasePendoEventProperties<PendoEventType>["eventType"], T] => {
    return [
      eventType,
      {
        ...eventProperties,
        SESSION_UNSTABLE: this.user.SESSION_UNSTABLE,
        timestamp: new Date().toISOString(),
      },
    ];
  };

  private formatPendoIdentityFromUser = () => {
    return {
      visitor: {
        id: this.user.id,
        email: this.user.email,
        name: this.user.name,
        organization: this.user.organization ?? null,
        partnerId: this.user.partnerId ?? null,
        SESSION_UNSTABLE: this.user.SESSION_UNSTABLE,
      },
      account: {
        id: this.user.organization ?? "UNKNOWN_ORGANIZATION",
        pricingTier: this.user.pricingTier ?? null,
        partnerId: this.user.partnerId ?? null,
      },
    };
  };
}
