// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Skeleton from "@mui/material/Skeleton";
import { ReactNode, Suspense } from "react";

export type GraftSuspenseProps = {
  fallback?: ReactNode | null;
  children: ReactNode;
};

/**
 * Suspense with a default fallback of a shimmering background.
 */
export function GraftSuspense({
  children,
  fallback = <ShimmeringBackground />,
}: GraftSuspenseProps) {
  return <Suspense fallback={fallback}>{children}</Suspense>;
}

function ShimmeringBackground() {
  return <Skeleton animation="pulse" width="100%" height="100%" />;
}
