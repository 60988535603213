// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Alert, { AlertColor } from "@mui/material/Alert";
import Box from "@mui/material/Box";
import { FC, VFC, useState } from "react";
import { parseGraftError } from "../../helpers/graftError";
import { Styles } from "../../types/styles";
import { GraftLink } from "../link/GraftLink";
import { GraftTypography } from "../typography/GraftTypography";
import { useLocalizationManager } from "../../hooks/managers/useLocalizationManager";
import { formatTimestampForError, unixTimeNow } from "../../helpers/date";
import { graftTheme } from "../../styles/themes/graft";

export type GraftErrorCardProps = GraftErrorInlineProps & {
  variant?: AlertColor;
  onClose?: () => void;
};

/**
 * Parse and display the error without in a container.
 */
export const GraftErrorCard: VFC<GraftErrorCardProps> = ({
  variant = "error",
  message,
  defaultTitle,
  defaultCode,
  onClose,
}) => {
  return (
    <Alert
      severity={variant}
      sx={{
        ".MuiAlert-message": { width: "100%" },
      }}
      onClose={onClose}
    >
      <GraftErrorInline
        message={message}
        defaultTitle={defaultTitle}
        defaultCode={defaultCode}
      />
    </Alert>
  );
};

export type GraftErrorInlineProps = {
  message: string;
  defaultTitle?: string | null;
  defaultCode?: string | null;
};

/**
 * Parse and display the error without container styling.
 */
export const GraftErrorInline: FC<GraftErrorInlineProps> = ({
  message: originalMessage,
  defaultTitle,
  defaultCode,
}) => {
  const parsed = parseGraftError(originalMessage);
  const title = parsed.title ?? defaultTitle;
  const code = parsed.code ?? defaultCode;
  const { message, details, log_id, request_id, timestamp } = parsed;
  const hints = [
    code,
    // show request_id only if we don't have log_id
    log_id ?? request_id,
    formatTimestampForError(timestamp ?? unixTimeNow()),
  ].filter((v) => v != null);
  return (
    <Box data-testid="graft_error_card">
      {title && (
        <Box>
          <GraftTypography variant="h6">{title}</GraftTypography>
        </Box>
      )}
      <Box>
        <GraftTypography variant="body1">{message}</GraftTypography>
      </Box>
      {hints.length > 0 && (
        <Box sx={styles.hints}>
          <code>{hints.join(" / ")}</code>
        </Box>
      )}
      <GraftErrorCollapsibleDetails details={details} />
    </Box>
  );
};

export type GraftErrorCollapsibleDetails = {
  details: string | null | undefined;
};

export function GraftErrorCollapsibleDetails({
  details,
}: GraftErrorCollapsibleDetails) {
  const localizationManager = useLocalizationManager();
  const [showDetails, setShowDetails] = useState(false);
  if (!details) {
    return null;
  }
  return (
    <>
      <Box sx={{ my: ".5rem", fontSize: "0.8rem" }}>
        <GraftLink
          color="secondary"
          onClick={() => setShowDetails(!showDetails)}
        >
          {showDetails ? "Hide" : "Show"} details...
        </GraftLink>
      </Box>
      {showDetails && (
        <pre style={styles.details}>{localizationManager.get(details)}</pre>
      )}
    </>
  );
}

const styles: Styles = {
  hints: {
    color: "#c4908f",
    margin: ".5rem 0 0 0",
    ...graftTheme.typography.pre,
    fontSize: "0.8rem",
  },
  details: {
    fontSize: "0.8rem",
    maxHeight: "400px",
    overflow: "auto",
    padding: "0.5rem",
    borderRadius: "0.5rem",
    margin: ".5rem 0 0 0",
    whiteSpace: "pre-wrap",
    color: "#000000",
    backgroundColor: "#FFFFFF",
  },
};
