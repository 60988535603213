// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftUserProps, UNKNOWN_EMAIL } from "../../gql/local/GraftUserVar";
import { Organization } from "../../gql/__generated__/types";
import { GraftConfig } from "../../hooks/useGraftConfig";

export class PendoUser {
  readonly id: string;
  readonly email: string;
  readonly firstName: string;
  readonly lastName: string;
  readonly SESSION_UNSTABLE: string;
  readonly organization?: Organization["name"];
  readonly pricingTier?: Organization["pricingTier"];
  readonly partnerId?: GraftConfig["partnerId"];

  constructor({
    email,
    firstName,
    lastName,
    organizationName,
    pricingTier,
    id,
    partnerId,
    SESSION_UNSTABLE,
  }: GraftUserProps) {
    this.firstName = firstName;
    this.lastName = lastName;
    this.id = email !== UNKNOWN_EMAIL ? email : this.name ?? id;
    this.email = email;
    this.organization = organizationName;
    this.pricingTier = pricingTier;
    this.partnerId = partnerId;
    this.SESSION_UNSTABLE = SESSION_UNSTABLE;
  }

  get name(): string {
    return `${this.firstName} ${this.lastName}`;
  }
}
