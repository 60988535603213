// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { uniqueId } from "lodash";
import { getCurrentEpochSeconds } from "../../helpers/utility";
/**
 * These match python log levels.
 */
export enum LogLevel {
  DEBUG = 10,
  INFO = 20,
  WARN = 30,
  ERROR = 40,
  CRITICAL = 50,
}

export type LogMessageParam = string | number | boolean | null;

export type LogMessage = {
  message: string;
  params: { [k: string]: LogMessageParam };
  errorStack?: string;
  componentStack?: string;
  /** An error dialog title to show in case the message does not have an embedded one */
  defaultTitle?: string;
  level: LogLevel;
};

export type PublishedLogMessage = LogMessage & {
  timestamp: number;
  id: string;
};

export type LogListener = (logMessage: PublishedLogMessage) => void;

const listeners = [] as LogListener[];

let publishing = false;

export const logPubSub = {
  publish(logMessage: LogMessage): void {
    if (publishing) {
      // safeguard against infinite loop
      return;
    }
    publishing = true;
    for (const callback of listeners) {
      try {
        callback({
          ...logMessage,
          timestamp: getCurrentEpochSeconds(),
          id: uniqueId("error_message_"),
        });
      } catch {
        // ignore
      }
    }
    publishing = false;
  },

  subscribe(listener: LogListener): void {
    listeners.push(listener);
  },

  unsubscribe(listener: LogListener) {
    let index;
    while ((index = listeners.indexOf(listener)) >= 0) {
      listeners.splice(index, 1);
    }
  },
};

/**
 * Basic subscriber that echoes errors to console.
 */
logPubSub.subscribe((logMessage) => {
  const extra =
    logMessage.errorStack != null || logMessage.componentStack != null
      ? {
          errorStack: logMessage.errorStack,
          componentStack: logMessage.componentStack,
        }
      : undefined;
  console.error(logMessage.message, extra);
});

/**
 * Capture window errors.
 */
if (typeof window !== "undefined") {
  window.addEventListener("error", (event) => {
    if (BENIGN_ERRORS.has(event.message)) {
      return;
    }
    logPubSub.publish({
      message: `ONERROR: ${event.message}`,
      params: {},
      level: LogLevel.ERROR,
    });
  });
}

const BENIGN_ERRORS = new Set([
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.",
]);
