// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { DebouncedFunc, DebounceSettings, ThrottleSettings } from "lodash";
import _cloneDeep from "lodash/cloneDeep";
import _debounce from "lodash/debounce";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";
import _sum from "lodash/sum";
import _throttle from "lodash/throttle";
import _pick from "lodash/pick";
import _get from "lodash/get";

export function noop() {
  // do nothing
}

export function zip<T1, T2>(a: readonly T1[], b: readonly T2[]): [T1, T2][] {
  if (a.length !== b.length) {
    throw new Error("Cannot zip arrays of different arities");
  }
  return a.map((elem, i) => [elem, b[i]]);
}

export function functionIsNoop(fString: string) {
  return fString === noop.toString();
}

export function tautology() {
  return true;
}

export function contradiction() {
  return false;
}

export function not<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

export function intersection<T>(a: readonly T[], b: readonly T[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

/** Performs the intersection of two arrays, both ways. */
export function diffArray(arrA: string[] = [], arrB: string[] = []) {
  return arrA
    .concat(arrB)
    .filter((item) => !arrA.includes(item) || !arrB.includes(item));
}

export function union<T>(a: readonly T[], b: readonly T[]) {
  return [...a, ...not(b, a)];
}

export function range(end: number, step = 1, begin = 0) {
  let res: number[] = [];
  for (let i = begin; i < end; i += step) {
    res = [...res, i];
  }
  return res;
}

export function none(xs: boolean[]) {
  return xs.length == 0 || xs.findIndex((x) => x) == -1;
}

export function any(xs: boolean[]) {
  return xs.findIndex((x) => x) != -1;
}

export function all(xs: boolean[]) {
  return xs.length == 0 || xs.findIndex((x) => !x) == -1;
}

export function iff(xs: boolean[]) {
  return all(xs) || none(xs);
}

export function pgSanitize(name: string) {
  // Sanitizes given PostgreSQL name to only contain characters that don't need escaping.
  // See graft/database/utils/pg_name.py
  return name.toLowerCase().replace(/[^\w]/g, "_");
}

export function capitalizeString(s: string) {
  return `${s.slice(0, 1).toUpperCase()}${s.slice(1).toLowerCase()}`;
}

export function getCurrentEpochSeconds() {
  return Math.floor(Date.now() / 1000);
}

export function getStemFromPath(path: string) {
  return path.split("/").slice(-1)[0];
}

export function secondsToTimeString(secs: number) {
  secs = Math.round(secs);
  const hours = Math.floor(secs / (60 * 60));

  const divisor_for_minutes = secs % (60 * 60);
  const minutes = Math.floor(divisor_for_minutes / 60);

  const divisor_for_seconds = divisor_for_minutes % 60;
  const seconds = Math.ceil(divisor_for_seconds);

  if (hours !== 0) {
    return `${hours}h ${minutes}m ${seconds}s`;
  } else if (minutes !== 0) {
    return `${minutes}m ${seconds}s`;
  } else {
    return `${seconds}s`;
  }
}

export async function sha256(message: string) {
  // encode as UTF-8
  const msgBuffer = new TextEncoder().encode(message);

  // hash the message
  const hashBuffer = await crypto.subtle.digest("SHA-256", msgBuffer);

  // convert ArrayBuffer to Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // convert bytes to hex string
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
}

/** Creates a standardized `data-testid` for identifying components for testing */
export const testid = (
  testIdDefault: string,
  testIdOverride?: string,
): string => testIdOverride || `graft_${pgSanitize(testIdDefault)}`;

/** Groups an array of objects by a specfied key. */
export function groupBy<K extends string | number, T extends object>(
  objs: T[],
  key: keyof T | ((value: T, i: number) => K),
): Record<K, T[]> {
  const groups: Record<string | number, T[]> = {};
  objs.forEach((obj, i) => {
    const groupKey =
      typeof key === "function" ? key(obj, i) : (obj[key] as string);
    if (!groups[groupKey]) {
      groups[groupKey] = [];
    }
    groups[groupKey].push(obj);
  });
  return groups;
}

/** Deduplicates an array of values. */
export function unique(ary: string[] | undefined) {
  return ary ? [...new Set(ary)] : ary;
}

/** Deduplicates an array of objects by a particular prop who's value is a string. */
export function uniqueBy<T>(ary: T[], key: keyof T): T[] {
  return unique(ary.map((obj) => obj[key] as string))
    ?.map((value) => ary.find((obj) => obj[key] === value))
    .filter((model) => Boolean(model)) as T[];
}

/** Checks to see if value is an array and if not, converts it to one */
export function createArray<T extends string | number>(value: T | T[]) {
  if (Array.isArray(value)) {
    return value;
  }
  if (value == null) {
    return [];
  }
  return [value];
}

export function filterNulls<T>(items: (T | null | undefined)[]): T[] {
  return items.filter((item) => item != null) as T[];
}

type ObjectType<K extends keyof V, V> = Record<K, V[K]>;
type ValueType<V> = V extends ObjectType<keyof V, V> ? V[keyof V] : never;

export function objectReduce<V, R>(
  obj: ObjectType<keyof V, V>,
  iteratee: (acc: R, key: keyof V, value: ValueType<V>) => R = (acc) => acc,
  initAcc: R = {} as R,
): R {
  return (Object.entries(obj) as [keyof V, ValueType<V>][]).reduce(
    (acc, [k, v]) => iteratee(acc, k, v),
    initAcc,
  );
}

/**
 *  Wrapped third party functions
 *
 *  This pattern allows us to import from the utility file
 *  while abstracting out the specific third party library
 *  and allowing for customizations (e.g. see "isEmpty")
 */

export function cloneDeep<T>(value: T): T {
  return _cloneDeep(value);
}

export function pick<T>(value: T, ...props: string[]): Partial<T> {
  return _pick(value, ...props);
}

export function get<T>(value: T, path: string) {
  return _get(value, path);
}

/** Deep equality assertions */

export function isEqual(value: any, other: any) {
  return _isEqual(value, other);
}

export function isNotEqual(value: any, other: any) {
  return !_isEqual(value, other);
}

/** Collection utilities
 *
 * Note: The imported "isEmpty" accepts any type and returns a boolean.
 * However, in a well-typed environment, we can and should be more specific.
 * Limiting acts as a conversion guard and is more declarative of expected use.
 */

type NullishCollectionTypes = Record<any, any> | any[] | null | undefined;
type CollectionTypes = Exclude<NullishCollectionTypes, null | undefined>;

export function isEmpty(value: CollectionTypes) {
  return _isEmpty(value);
}

export function isNotEmpty(value: CollectionTypes) {
  return !_isEmpty(value);
}

/** Sums a specific property of an object[] together (i.e. pluck and sum). */
export function sumBy<T>(values: T[], key: keyof T): number {
  return _sum(values.map((x) => x[key]));
}

/** Function utilities */

export function debounce<T extends (...args: any[]) => any>(
  func: T,
  wait?: number | undefined,
  options?: DebounceSettings,
): DebouncedFunc<T> {
  return _debounce(func, wait, options);
}

export function throttle<T extends (...args: any[]) => any>(
  func: T,
  wait?: number | undefined,
  options?: ThrottleSettings,
): DebouncedFunc<T> {
  return _throttle(func, wait, options);
}

/** Courtesy of https://blog.oyam.dev/typescript-enum-values/ */
type EnumObject = { [key: string]: number | string };
type EnumObjectEnum<E extends EnumObject> = E extends {
  [key: string]: infer ET | string;
}
  ? ET
  : never;
export function getEnumValues<E extends EnumObject>(
  enumObject: E,
): EnumObjectEnum<E>[] {
  return Object.keys(enumObject)
    .filter((key) => Number.isNaN(Number(key)))
    .map((key) => enumObject[key] as EnumObjectEnum<E>);
}

export function stringInsert(
  str: string,
  value: string,
  indexRange: number[] | number,
): string {
  const range = Array.isArray(indexRange)
    ? indexRange
    : [indexRange, indexRange];
  return str.slice(0, range[0]) + value + str.slice(range[1]);
}
