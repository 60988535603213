// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useEffect } from "react";

/** Intentionally should not work if compile errors */
export const useShiftEscClearDevOverlay = () => {
  useEffect(() => {
    const handleEscKeypress = (e: KeyboardEvent) => {
      if (e.key === "Escape" && e.shiftKey) {
        const iFrame: HTMLIFrameElement | null = document.querySelector(
          "#webpack-dev-server-client-overlay",
        );
        const innerDoc =
          iFrame?.contentDocument || iFrame?.contentWindow?.document;
        innerDoc?.querySelector("button")?.click();
      }
    };

    if (window.location.hostname === "localhost") {
      document.addEventListener("keydown", handleEscKeypress);
    }
    return () => {
      document.removeEventListener("keydown", handleEscKeypress);
    };
  }, []);
};
