// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import "@fontsource/commissioner/300.css";
import "@fontsource/commissioner/400.css";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { GlobalStyles } from "@mui/styled-engine";
import StyledEngineProvider from "@mui/styled-engine/StyledEngineProvider";
import { Fragment, ReactNode } from "react";
import { graftGlobalStyles, graftTheme } from "./themes/graft";

export function StyleProvider({ children }: { children: ReactNode }) {
  return (
    <Fragment>
      <GlobalStyles styles={graftGlobalStyles} />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={graftTheme}>{children}</ThemeProvider>
      </StyledEngineProvider>
    </Fragment>
  );
}
