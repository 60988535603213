// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiChip(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  const commonSizes = {
    "&.MuiChip-sizeSmall": {
      ...typography.overline,
      width: "6.25rem",
      height: "1.875rem",
    },
    "&.MuiChip-sizeMedium": {
      ...typography.overline,
      width: "auto",
      minWidth: "6.25rem",
      height: "1.875rem",
    },
  };
  return {
    root: {
      borderRadius: shape.borderRadius / 2,

      "&.MuiChip-colorError": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.error.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.error.contrastText,
          backgroundColor: palette.error.lighter,
          borderColor: palette.error.main,
        },
      },
      "&.MuiChip-colorInfo": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.info.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.info.contrastText,
          backgroundColor: palette.info.lighter,
          borderColor: palette.info.main,
        },
      },
      "&.MuiChip-colorPrimary": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.primary.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.primary.contrastText,
          backgroundColor: palette.primary.lighter,
          borderColor: palette.primary.main,
        },
      },
      "&.MuiChip-colorSecondary": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.secondary.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.secondary.contrastText,
          backgroundColor: palette.secondary.lighter,
          borderColor: palette.secondary.main,
        },
      },
      "&.MuiChip-colorSuccess": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.success.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.success.contrastText,
          backgroundColor: palette.success.lighter,
          borderColor: palette.success.main,
        },
      },
      "&.MuiChip-colorWarning": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.warning.contrastText,
          backgroundColor: palette.warning.main,
        },
        "&.MuiChip-outlined": {
          border: "solid 2px",
          color: palette.common.black,
          backgroundColor: palette.warning.lighter,
          borderColor: palette.warning.main,
        },
      },
      "&.MuiChip-colorDisabled": {
        ...commonSizes,

        "&:not(.MuiChip-outlined)": {
          color: palette.common.white,
          backgroundColor: palette.action.disabledBackground,
        },
        "&.MuiChip-outlined": {
          color: palette.common.black,
          backgroundColor: palette.action.disabledBackground,
        },
      },
    },
  };
}
