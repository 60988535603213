// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useEffect } from "react";
import { useGraftNavigate } from "../hooks/navigation/useGraftNavigate";

export type RedirectProps = {
  path: string;
};

export function Redirect({ path }: RedirectProps) {
  const graftNavigate = useGraftNavigate();
  useEffect(() => {
    graftNavigate(path);
  });
  return null;
}
