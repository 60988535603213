// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";

// TODO(eschkufz): Remove this theme definition once we've completely deprecated the old version of GraftCard.
//  These attributes are all set at the component level now.

export function muiCard(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
) {
  return {
    root: {
      borderRadius: shape.borderRadius,
      backgroundColor: palette.secondary.lighter,
      border: "none",
      boxShadow: "none",
    },
  };
}
