// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useReactiveVar } from "@apollo/client";
import { GraftUserVar } from "../gql/local/GraftUserVar";

export function useGraftUser() {
  return useReactiveVar(GraftUserVar);
}

export function useGraftUserSettings() {
  return useGraftUser().settings;
}

export function useCanViewGraftOnlyContent() {
  const viewAsNonGraftUser =
    useGraftUserSettings().getSetting("viewAsNonGraftUser");
  return useGraftUser().isGraftEmployee && !viewAsNonGraftUser;
}
