// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiBreadcrumbs(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      ".MuiBreadcrumbs-li": {
        ...typography.caption,
        textTransform: "uppercase" as const,

        "&:last-of-type": {
          color: palette.primary.main,
        },
      },
    },
  };
}
