// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiTypography(typography: GraftTypographyOptions) {
  const weights = {
    "&.graft_weight_light": { fontWeight: typography.weight.light },
    "&.graft_weight_regular": { fontWeight: typography.weight.regular },
    "&.graft_weight_medium": { fontWeight: typography.weight.medium },
    "&.graft_weight_semiBold": { fontWeight: typography.weight.semiBold },
    "&.graft_weight_bold": { fontWeight: typography.weight.bold },
  };
  const transforms = {
    "&.graft_transform_uppercase": { textTransform: "uppercase" as const },
    "&.graft_transform_none": { textTransform: "none" },
  };
  return {
    root: {
      "&.MuiTypography-h1": { ...typography.h1, ...weights, ...transforms },
      "&.MuiTypography-h2": { ...typography.h2, ...weights, ...transforms },
      "&.MuiTypography-h3": { ...typography.h3, ...weights, ...transforms },
      "&.MuiTypography-h4": { ...typography.h4, ...weights, ...transforms },
      "&.MuiTypography-h5": { ...typography.h5, ...weights, ...transforms },
      "&.MuiTypography-h6": { ...typography.h6, ...weights, ...transforms },
      "&.MuiTypography-body1": {
        ...typography.body1,
        ...weights,
        ...transforms,
      },
      "&.MuiTypography-button": {
        ...typography.button,
        ...weights,
        ...transforms,
      },
      "&.MuiTypography-caption": {
        ...typography.caption,
        ...weights,
        ...transforms,
      },
      "&.MuiTypography-overline": {
        ...typography.overline,
        ...weights,
        ...transforms,
      },
    },
  };
}
