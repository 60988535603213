// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { makeVar } from "@apollo/client";
import { User } from "@auth0/auth0-react";
import { getUserSettingsKey, isUserGraftEmployee } from "../../helpers/user";
import { GraftUserSettings } from "../models/GraftUserSettings";
import { Organization } from "../__generated__/types";
import { nanoid } from "nanoid";
import { GraftConfig } from "../../hooks/useGraftConfig";

export const UNKNOWN_EMAIL = "UNKNOWN_USER_EMAIL";
export const CYPRESS_EMAIL = "cypress-test-user@graft.com";

export interface GraftUserProps {
  email: string;
  firstName: User["firstName"];
  lastName: User["lastName"];
  id: User["sub"];
  picture: User["picture"];
  organizationName?: Organization["name"];
  pricingTier?: Organization["pricingTier"];
  partnerId?: GraftConfig["partnerId"];
  isGraftEmployee: boolean;
  settings: GraftUserSettings;
  SESSION_UNSTABLE: string;
}

export const graftUserInitialValue: GraftUserProps = {
  email: "",
  firstName: "",
  lastName: "",
  id: "",
  picture: "",
  isGraftEmployee: false,
  settings: new GraftUserSettings("", false),
  SESSION_UNSTABLE: nanoid(),
};

export const GraftUserVar = makeVar<GraftUserProps>(graftUserInitialValue);

export function initGraftUser(user: User) {
  const {
    email = UNKNOWN_EMAIL,
    given_name: firstName,
    family_name: lastName,
    sub: id = "|DEFAULT_USER_ID",
    picture,
  } = user;
  const isGraftEmployee = isUserGraftEmployee(email);
  const settingsStorageKey = getUserSettingsKey(id);
  const settings = new GraftUserSettings(settingsStorageKey, isGraftEmployee);
  settings.initialize();
  const graftUser: GraftUserProps = {
    ...graftUserInitialValue,
    email,
    firstName,
    lastName,
    id,
    picture,
    isGraftEmployee,
    settings,
  };

  GraftUserVar(graftUser);
}
