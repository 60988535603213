// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import createTheme, { Theme } from "@mui/material/styles/createTheme";
import { BreakpointsOptions } from "@mui/system/createTheme/createBreakpoints";
import { GraftPaletteOptions } from "../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../types/GraftTypographyOptions";
import { muiBreadcrumbs } from "./components/muiBreadcrumbs";
import { muiButton } from "./components/muiButton";
import { muiCard } from "./components/muiCard";
import { muiCardContent } from "./components/muiCardContent";
import { muiChip } from "./components/muiChip";
import { muiCircularProgress } from "./components/muiCircularProgress";
import { muiDataGrid } from "./components/muiDataGrid";
import { muiDialog } from "./components/muiDialog";
import { muiFormControl } from "./components/muiFormControl";
import { muiIconButton } from "./components/muiIconButton";
import { muiLinearProgress } from "./components/muiLinearProgress";
import { muiLink } from "./components/muiLink";
import { muiListItem } from "./components/muiListItem";
import { muiListItemButton } from "./components/muiListItemButton";
import { muiMenuItem } from "./components/muiMenuItem";
import { muiRadio } from "./components/muiRadio";
import { muiSpeedDialAction } from "./components/muiSpeedDialAction";
import { muiSvgIcon } from "./components/muiSvgIcon";
import { muiSwitch } from "./components/muiSwitch";
import { muiTableCell } from "./components/muiTableCell";
import { muiTablePagination } from "./components/muiTablePagination";
import { muiTabs } from "./components/muiTabs";
import { muiTooltip } from "./components/muiTooltip";
import { muiTypography } from "./components/muiTypography";

// See https://github.com/mui/mui-x/issues/1755 for why this import is here
import type {} from "@mui/x-data-grid-pro/themeAugmentation";

type GraftTheme = Theme & {
  palette: GraftPaletteOptions;
  typography: GraftTypographyOptions;
};

export function createGraftTheme(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
  breakpoints: BreakpointsOptions,
) {
  return createTheme({
    components: {
      MuiBreadcrumbs: {
        styleOverrides: muiBreadcrumbs(palette, shape, typography),
      },
      MuiButton: { styleOverrides: muiButton(palette, typography) },
      MuiCard: { styleOverrides: muiCard(palette, shape) },
      MuiCardContent: {
        styleOverrides: muiCardContent(),
      },
      MuiCircularProgress: { styleOverrides: muiCircularProgress(palette) },
      MuiChip: { styleOverrides: muiChip(palette, shape, typography) },
      MuiDataGrid: { styleOverrides: muiDataGrid(palette, shape, typography) },
      MuiDialog: {
        styleOverrides: muiDialog(palette, shape, typography),
      },
      MuiFormControl: { styleOverrides: muiFormControl(palette, typography) },
      MuiRadio: { styleOverrides: muiRadio(palette, typography) },
      MuiSwitch: { styleOverrides: muiSwitch(palette, typography) },
      MuiIconButton: { styleOverrides: muiIconButton(palette) },
      MuiLink: { styleOverrides: muiLink() },
      MuiLinearProgress: { styleOverrides: muiLinearProgress(palette) },
      MuiListItem: { styleOverrides: muiListItem(palette, typography) },
      MuiListItemButton: {
        styleOverrides: muiListItemButton(palette, shape),
      },
      MuiMenuItem: { styleOverrides: muiMenuItem(palette, typography) },
      MuiSpeedDialAction: { styleOverrides: muiSpeedDialAction(palette) },
      MuiSvgIcon: { styleOverrides: muiSvgIcon(palette) },
      MuiTableCell: { styleOverrides: muiTableCell(typography) },
      MuiTablePagination: { styleOverrides: muiTablePagination(typography) },
      MuiTabs: { styleOverrides: muiTabs(typography) },
      MuiTooltip: { styleOverrides: muiTooltip(palette, typography) },
      MuiTypography: { styleOverrides: muiTypography(typography) },

      // TODO(eschkufz): Refactor this properly
      MuiIcon: { styleOverrides: { root: { width: "auto", height: 25 } } },
    },
    palette,
    shape,
    typography,
    breakpoints,
  }) as GraftTheme;
}
