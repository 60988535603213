// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import "@mui/material/Switch/Switch";
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

declare module "@mui/material/Switch/Switch" {
  interface SwitchPropsSizeOverrides {
    mini: true;
  }
}

export function muiSwitch(
  palette: GraftPaletteOptions,
  __: GraftTypographyOptions,
) {
  return {
    root: {
      width: 40,
      height: 26,
      margin: "0 8px",
      padding: 0,
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(14px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor: palette.action.selected,
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: palette.action.active,
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color: "#fff",
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          color: palette.action.disabled,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: palette.action.disabled,
        opacity: 1,
        transition:
          "left 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,transform 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      },

      "&.MuiSwitch-sizeMini": {
        width: 24,
        height: 14,
        margin: 0,
        padding: 0,
        "& .MuiSwitch-switchBase": {
          margin: 1,
          padding: 0,
          "&.Mui-checked": {
            transform: "translateX(10px)",
          },
        },
        "& .MuiSwitch-thumb": {
          boxSizing: "border-box",
          width: 12,
          height: 12,
        },
        "& .MuiSwitch-track": {
          borderRadius: "20px",
        },
      },
    },
  };
}
