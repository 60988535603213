// Copyright © 2021-Present Graft Inc. <copyright@graft.com>

/**
 * "User" related const/utilities.
 * With the understanding that the concept of "User"
 * is and will be evolving.
 */

const GRAFT_LS_PREFIX = "_gls-";

export const getUserSettingsKey = (userId: string) =>
  `${GRAFT_LS_PREFIX}${userId?.split("|")[1].toLowerCase()}`;

export const isUserGraftEmployee = (email: string) =>
  email.endsWith("@graft.com");
