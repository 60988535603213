// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { noop } from "../../helpers/utility";
import { createPortal } from "react-dom";

export type DrawerPanelRefCallback = Dispatch<
  SetStateAction<HTMLDivElement | null>
>;

export type DrawerPanelComponent = React.FC<Record<string, never>>;

export type DrawerPanelContent = {
  Component?: DrawerPanelComponent;
  setContainer?: DrawerPanelRefCallback;
  /**
   * !!!DEPRECATED, DO NOT USE (GRF-2604)!!!
   */
  reactNode?: ReactNode;
};

export type GraftDrawerProps = {
  width?: number;
} | null;

export type DrawerContextType = {
  primary: DrawerPanelContent | null;
  primaryProps: Readonly<GraftDrawerProps>;
  setPrimaryProps: React.Dispatch<SetStateAction<GraftDrawerProps>>;
  secondary: DrawerPanelContent | null;
  secondaryProps: Readonly<GraftDrawerProps>;
  setSecondaryProps: React.Dispatch<SetStateAction<GraftDrawerProps>>;
  pushRefCallback: (setContainer: DrawerPanelRefCallback) => void;
  pushComponent: (component: DrawerPanelComponent) => void;
  /**
   * !!!DEPRECATED, DO NOT USE (GRF-2604)!!!
   */
  push: (content: ReactNode) => void;
  pop: () => void;
  clear: () => void;
  setOnClear: React.Dispatch<SetStateAction<() => void>>;

  // Nonce is a non-repeating number which changes on every push/pop/clear operation. Nonce can be used to schedule
  // useEffect callbacks whenever the state of the drawer context changes.
  nonce: number;
  /** Triggers an update to reset the drawer by iterating the nonce. */
  reset: () => void;

  // Toggles whether panels below the top of the stack are usable. Resets on clear
  disableLower: boolean;
  setDisableLower: React.Dispatch<React.SetStateAction<boolean>>;
};

const DrawerContext = createContext<DrawerContextType | null>(null);

export const DrawerContextProvider: FC = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [disableLower, setDisableLower] = useState(true);
  const [nonce, setNonce] = useState(0);
  const [stack, setStack] = useState<DrawerPanelContent[]>([]);
  const [onClear, setOnClear] = useState(() => noop);
  const [primaryProps, setPrimaryProps] = useState<GraftDrawerProps>(null);
  const [secondaryProps, setSecondaryProps] = useState<GraftDrawerProps>(null);

  const pushContent = (content: DrawerPanelContent) => {
    setStack((prev) => [...prev, content]);
    setNonce((prev) => prev + 1);
  };

  const pushComponent: DrawerContextType["pushComponent"] = (
    Component: DrawerPanelComponent,
  ) => pushContent({ Component });

  const pushRefCallback: DrawerContextType["pushRefCallback"] = (
    setContainer: DrawerPanelRefCallback,
  ) => pushContent({ setContainer });

  const push: DrawerContextType["push"] = (reactNode: ReactNode) =>
    pushContent({ reactNode });

  const pop: DrawerContextType["pop"] = () => {
    setStack((prev) => {
      if (prev.length === 1) {
        onClear();
        setDisableLower(true);
        setPrimaryProps(null);
      } else {
        setSecondaryProps(null);
      }
      return prev.slice(0, prev.length - 1);
    });
    setNonce((prev) => prev + 1);
  };

  const clear: DrawerContextType["clear"] = () => {
    setStack([]);
    onClear();
    setDisableLower(true);
    setPrimaryProps(null);
    setSecondaryProps(null);
    setNonce((prev) => prev + 1);
  };

  const reset: DrawerContextType["reset"] = () => {
    setNonce((prev) => prev + 1);
  };

  return (
    <DrawerContext.Provider
      value={{
        primary: stack.length > 0 ? stack[0] : null,
        primaryProps,
        setPrimaryProps,
        secondary: stack.length > 1 ? stack[1] : null,
        secondaryProps,
        setSecondaryProps,
        pushComponent,
        pushRefCallback,
        push,
        pop,
        clear,
        setOnClear,
        nonce,
        reset,
        disableLower,
        setDisableLower,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = () => {
  const context = useContext(DrawerContext);
  if (context == null) {
    throw new Error("Must be wrapped with DrawerContextProvider");
  }
  return context;
};

export function useDrawerPanel() {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const drawer = useDrawer();
  return {
    open: () => {
      drawer.pushRefCallback(setContainer);
    },
    portal: (children: ReactNode) => {
      return container ? createPortal(children, container) : null;
    },
  };
}
