// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { nanoid } from "nanoid";
import { unixTimeNow } from "./date";
import { stringifyGraftError } from "./graftError";

export const useAuthorizationHeader = () => {
  const getAuthHeaders = useAuthorizationHeaders();
  const [authHeader, setAuthHeader] = useState<string | null>(null);
  const expirationHash = useAuthorizationExpiration();

  useEffect(() => {
    getAuthHeaders().then(({ authorization }) => setAuthHeader(authorization));
  }, [expirationHash]);

  return authHeader;
};

export const useAuthorizationHeaders = () => {
  const { getAccessTokenSilently } = useAuth0();
  return async () => {
    const token = await getAccessTokenSilently();
    if (!token) {
      const graftError = stringifyGraftError({
        title: "$title_something_went_wrong",
        message: "$graft_is_having_issues",
        code: `AUTH0_NO_TOKEN`,
      });
      throw Error(graftError);
    }
    return { authorization: `Bearer ${token}` };
  };
};

/** Tracks when a token is expired to enable a hook to automatizally refetch the new token. */
const useAuthorizationExpiration = () => {
  const { getIdTokenClaims } = useAuth0();
  const [hash, setHash] = useState<string>(nanoid());

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;

    (async () => {
      const claims = await getIdTokenClaims();
      const now = unixTimeNow();
      const expiresInMs = claims?.exp ? (claims.exp - now) * 1000 : undefined;
      if (expiresInMs) {
        timeout = setTimeout(() => {
          setHash(nanoid());
        }, expiresInMs);
      }
    })();

    return () => clearTimeout(timeout);
  }, [hash]);

  return hash;
};

/** Redirects the user to the login screen with pertinent session metadata. */
export const useAuthorizationLoginWithRedirect = () => {
  const { loginWithRedirect, user } = useAuth0();
  return (reason?: "session-expired") =>
    loginWithRedirect({
      organization: user?.org_id,
      redirectUri: window.location.origin + "/projects",
      appState: { returnTo: window.location.pathname + window.location.search },
      // NOTE (patmigliaccio): Adds a parameter to the Authorized server request which is accessible
      // via the Universal Login page Custom HTML. In the future, we can conditionally display
      // a banner or notification on the Login page with a message detailing the reason for the redirect.
      // https://auth0.com/docs/customize/universal-login-pages/universal-login-page-templates#custom-query-parameters
      "ext-reason": reason,
    });
};
