// Copyright © 2021-Present Graft Inc. <copyright@graft.com>

/**
 * There are a lot of scenarios where we can pass error only as a single
 * string: message.
 *
 * For example when throwing. Or even when returning an error from the server.
 *
 * But in many cases we want to show a nice error message to the user,
 * but at the same time also provide some details or a code that can aid debugging.
 *
 * So let's embed some extra fields in said error messages via special <gfterr> tag.
 */

export type GraftError = {
  /* User friendly error message. */
  message: string;

  /* Error dialog title. */
  title?: string;

  /* An easy to spell and identify code. */
  code?: string;

  /* An unique identifier for error in logs (graft.log_id). */
  log_id?: string;

  /* An unique identifier for request in logs (graft.request_id). */
  request_id?: string;

  /* When did this error happen? */
  timestamp?: number;

  /*
   * Nitty gritty details to aid troubleshooting, these are likely very technical
   * and not user actionable.
   */
  details?: string;
};

const ERROR_REGEXP = /^([\s\S]*)<gfterr>(\{[\s\S]*\})<\/gfterr>([\s\S]*)$/;

/**
 * Extract embedded GraftError from an error message.
 *
 * !!!IMPORTANT!!! Do not use simply to pass along the message, this method should be used
 * by the component that renders the message, so it gets a chance to also render the error code.
 */
export function parseGraftError(message: string): GraftError {
  const matches = message?.match(ERROR_REGEXP);
  if (matches != null) {
    const [_, prefix, stringGraftError, postfix] = matches;
    try {
      const graftError = JSON.parse(stringGraftError);
      if (prefix || postfix) {
        if (!graftError.details) {
          graftError.details = `${prefix}<gfterr />${postfix}`;
        }
      }
      return graftError;
    } catch (e) {
      // malformed JSON, skip parsing
    }
  }
  return { message };
}

/**
 * Extract well known types of errors, in context of "What can we do about it?"
 */
export function parseGraftErrorFlags(message?: string | null) {
  // TODO (mick): these flags should be set when error is created, so we don't
  // manually parse error codes.
  const errorCode = message && parseGraftError(message).code;
  switch (errorCode) {
    // These 50X error happen when ALB can't reach our pods,
    // and are transient errors.
    case "CLIENT_HTTP_502":
    case "CLIENT_HTTP_503":
    case "CLIENT_HTTP_504":
    case "FAILED_TO_FETCH":
    case "FETCH_EXCEPTION":
      return {
        transient: true,
        connectivity: true,
      };
    case "NOT_FOUND":
      return {
        notFound: true,
      };
    case "AUTHORIZATION":
      return {
        noAccess: true,
      };
  }
  return {};
}

/**
 * Serialize graftError into a format such that it can be later extracted
 * via parseGraftError.
 */
export function stringifyGraftError(graftError: GraftError): string {
  return `<gfterr>${JSON.stringify(graftError)}</gfterr>`;
}
