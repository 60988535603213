// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiTooltip(
  palette: GraftPaletteOptions,
  typography: GraftTypographyOptions,
) {
  return {
    tooltip: {
      ...typography.caption,
      backgroundColor: palette.primary.main,
      padding: 8,
    },
    arrow: {
      color: palette.primary.main,
    },
  };
}
