// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { BrowserRouter } from "react-router-dom";
import { GraftErrorBoundary } from "../../components/error/GraftErrorBoundary";
import { DrawerContextProvider } from "../../components/page/useDrawer";
import { GraftAuth0ProviderWithAppRedirect } from "../GraftAuth0Provider";
import { PreloadedAssets } from "../PreloadedAssets";
import AppContainer from "./AppContainer";
import { GraftApolloProvider } from "../../gql/GraftApolloProvider";

export default function App() {
  return (
    <BrowserRouter>
      <GraftErrorBoundary location="App - Outermost" autoReset>
        <DrawerContextProvider>
          <GraftAuth0ProviderWithAppRedirect>
            <GraftApolloProvider>
              <PreloadedAssets />
              <AppContainer />
            </GraftApolloProvider>
          </GraftAuth0ProviderWithAppRedirect>
        </DrawerContextProvider>
      </GraftErrorBoundary>
    </BrowserRouter>
  );
}
