// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";

export function muiCircularProgress(palette: GraftPaletteOptions) {
  return {
    root: {
      "&.MuiCircularProgress-colorPrimary": {
        color: palette.primary.main,
      },
      "&.MuiCircularProgress-colorSecondary": {
        color: palette.secondary.main,
      },
    },
  };
}
