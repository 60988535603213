// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { makeVar, useReactiveVar } from "@apollo/client";
import { GraftUserProps } from "../../gql/local/GraftUserVar";
import { useGraftUser } from "../../hooks/useGraftUser";
import { GraftGoogleTagManager } from "../google/GraftGoogleTagManager";
import { GraftPendo } from "../pendo/GraftPendo";
import {
  AnalyticsEventMetadata,
  AnalyticsEventType,
  AnalyticsService,
} from "./types";

class GraftAnalytics implements AnalyticsService {
  private services: AnalyticsService[];
  constructor(private user: GraftUserProps) {
    this.services = [
      new GraftPendo(this.user),
      new GraftGoogleTagManager(this.user),
    ];
  }

  initialize(): void {
    this.services.forEach((service) => service.initialize());
  }

  track<TMetadata extends AnalyticsEventMetadata>(
    eventType: AnalyticsEventType,
    eventProperties: TMetadata,
  ): void {
    this.services.forEach((service) =>
      service.track(eventType, eventProperties),
    );
  }

  page(): void {
    this.services.forEach((service) => service.page());
  }
}

export function useGraftAnalytics() {
  const user = useGraftUser();
  const analyticsVar = makeVar<GraftAnalytics>(new GraftAnalytics(user));
  return useReactiveVar(analyticsVar);
}
