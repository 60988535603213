// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { LogLevel, LogMessage, logPubSub } from "./logPubSub";

export type PartialErrorLogMessage = Partial<LogMessage> & { message: string };

/**
 * Publish an error message without throwing.
 */
export function publishError(partial: PartialErrorLogMessage) {
  logPubSub.publish({
    params: {},
    level: LogLevel.ERROR,
    ...partial,
  });
}

const PUBLISHED = new Set();

/**
 * Publish an error message without throwing, and make sure it's published only once.
 * This is useful when publishing from the render code of react.
 */
export function publishReactError(partial: PartialErrorLogMessage) {
  if (PUBLISHED.has(partial.message)) {
    return;
  }
  PUBLISHED.add(partial.message);
  publishError(partial);
}
