// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ApolloClient, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import {
  useAuthorizationHeaders,
  useAuthorizationLoginWithRedirect,
} from "../helpers/auth";
import { apolloCache } from "./apolloCache";
import { graftFetch } from "../rest/graftFetch";

const GRAPHQL_ENDPOINT = "/internal/graphql";

export const useGraftApolloClient = () => {
  const getAuthHeaders = useAuthorizationHeaders();
  const loginWithRedirect = useAuthorizationLoginWithRedirect();

  const headersLink = setContext(async (_, { headers, ...rest }) => {
    const customHeaders = await getAuthHeaders();
    return {
      ...rest,
      headers: {
        ...headers,
        ...customHeaders,
      },
    };
  });

  // createUploadLink is a replacement for createHttpLink from "@apollo/client" that supports file uploads
  const fetchLink = createUploadLink({
    uri: GRAPHQL_ENDPOINT,
    fetch: async (uri, options) => {
      const response = await graftFetch(uri, options);
      if (response.status === 401) {
        await loginWithRedirect("session-expired");
      }
      return response;
    },
  });

  const apolloClient = new ApolloClient({
    link: ApolloLink.from([headersLink, fetchLink]),
    cache: apolloCache,
  });

  return apolloClient;
};
