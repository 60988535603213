// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiFormControl(
  palette: GraftPaletteOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      "& .MuiInputBase-root": {
        ...typography.body1,
        backgroundColor: palette.common.white,
        "&.Mui-disabled": {
          backgroundColor: palette.action.disabledBackground,
        },
        "& .MuiInputBase-adornedEnd": {
          paddingRight: 0,
        },
      },
      "& .MuiOutlinedInput-root": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: palette.secondary.darker,
        },
        "&:hover": {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: palette.primary.main,
          },
        },
        "&.Mui-disabled": {
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
      "& .MuiInputLabel-root": {
        color: palette.secondary.dark,
        "&.Mui-disabled": {
          color: palette.secondary.dark,
        },
      },
      "& .MuiOutlinedInput-input": {
        color: palette.secondary.darker,
        "&::placeholder": {
          color: palette.secondary.dark,
          opacity: 1,
        },
        "&.Mui-disabled": {
          textFillColor: "initial",
        },
      },
      "& .MuiFormLabel-root": {
        ...typography.body1,
      },
      "& .MuiFormControlLabel-label": {
        ...typography.body1,
      },
      "& .MuiFormControlLabel-labelPlacementStart": {
        margin: 0,
      },
      input: {
        padding: "0.5rem",
      },
      "input, textarea": {
        color: palette.secondary.darker,
        "&::placeholder": {
          color: palette.secondary.dark,
          opacity: 1,
        },
      },
    },
  };
}
