// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export const ALTERNATE_ROW_SELECT_CLASS = "graft-selected-row-alternate";

export function muiDataGrid(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    root: {
      ...getCommon(palette),
      "&.graft-default-table": getDefault(palette, shape, typography),
      "&.graft-list-table": getList(palette, shape, typography),
      "&.graft-log-table": getLog(palette, shape, typography),
      "&.graft-stepper-table": getStepper(palette, shape, typography),

      //flags
      "&.--alternateRowSelectClass": getAlternateRowSelect(palette),
    },
  };
}

function getCommon(palette: GraftPaletteOptions) {
  return {
    "& .MuiDataGrid-columnHeader": {
      "&:active": { outline: "none" },
      "&:focus": { outline: "none" },
      "&:selected": { outline: "none" },
      "&--sortable": { outline: "none" },

      "& .MuiDataGrid-columnSeparator": {
        "&--resizable": {
          color: palette.secondary.darker,
        },
      },
    },
    "& .MuiDataGrid-row": {
      "& .MuiDataGrid-cell": {
        "&:active": { outline: "none" },
        "&:focus": { outline: "none" },
        "&:selected": { outline: "none" },
        "&--withRenderer": { outline: "none" },
      },
    },
  };
}

function getDefault(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    backgroundColor: palette.common.white,
    border: "solid 0.5px",
    borderColor: palette.secondary.light,
    borderCollapse: "separate" as const,
    overflow: "hidden",

    "&.no-rows .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeaders": {
      ...typography.caption,
      textTransform: "uppercase" as const,
      color: palette.secondary.dark,
    },
    "& .MuiDataGrid-row": {
      ...typography.body1,
      "&:hover": {
        "&:first-of-type": {
          borderTop: "solid 0.5px",
          borderColor: palette.secondary.light,
        },
        "&.Mui-selected": {
          backgroundColor: palette.primary.lighter,
        },
        backgroundColor: palette.secondary.lighter,
      },
    },
  };
}

function getList(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    border: "none",

    // This is here because adding borders to rows causes overflow on the right-hand-size of the enclosing container
    "& .MuiDataGrid-main": {
      overflow: "visible",
      "& .MuiDataGrid-virtualScroller": {
        overflow: "visible !important",
      },
    },

    "& .MuiDataGrid-columnHeaders": {
      ...typography.caption,
      textTransform: "uppercase" as const,
      color: palette.secondary.dark,
      borderBottom: "none",
    },
    "& .MuiDataGrid-row": {
      backgroundColor: palette.common.white,
      border: "solid 1px",
      borderRadius: shape.borderRadius / 2,
      borderColor: palette.secondary.main,

      "&:hover": {
        backgroundColor: palette.primary.lighter,
        borderColor: palette.primary.main,
      },

      "& .MuiDataGrid-cell": {
        ...typography.body1,
        border: "none",
      },
    },
    "& .MuiDataGrid-footerContainer": {
      borderTop: "none",
    },
  };
}

function getLog(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    border: "none",

    "& .MuiDataGrid-columnHeaders": {
      ...typography.caption,
      textTransform: "uppercase" as const,
      backgroundColor: palette.secondary.lighter,
      color: palette.secondary.dark,
    },
    "& .MuiDataGrid-row": {
      ...typography.body1,
      backgroundColor: palette.common.white,
    },
  };
}

function getStepper(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
  typography: GraftTypographyOptions,
) {
  return {
    backgroundColor: palette.common.white,
    border: "solid 0.5px",
    borderColor: palette.secondary.light,
    borderCollapse: "separate" as const,
    overflow: "hidden",

    "&.no-rows .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },

    "& .MuiDataGrid-columnHeaders": {
      ...typography.caption,
      textTransform: "uppercase" as const,
      color: palette.secondary.darker,
    },
    "& .MuiDataGrid-row": {
      ...typography.body1,
      "&:hover": {
        "&:first-of-type": {
          borderTop: "solid 0.5px",
          borderColor: palette.secondary.light,
        },
        backgroundColor: palette.common.white,
      },
    },
  };
}

function getAlternateRowSelect(palette: GraftPaletteOptions) {
  return {
    "& .MuiDataGrid-row": {
      "&.Mui-selected": {
        backgroundColor: "transparent",
      },
      [`&.${ALTERNATE_ROW_SELECT_CLASS}`]: {
        backgroundColor: palette.primary.lighter,
      },
    },
  };
}
