// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";
import { GraftIdProps, GraftStatusProps } from "../../types/GraftProps";
import { useLocalizationManager } from "../../hooks/managers/useLocalizationManager";
import { PropsWithChildren } from "react";

export type GraftTooltipProps = GraftIdProps &
  GraftStatusProps &
  Pick<TooltipProps, "open" | "placement" | "followCursor" | "enterDelay">;

export function GraftTooltip({
  id,
  graftTestId,
  disabled,
  loading,
  info,
  error,
  children,
  open,
  placement,
  followCursor = true,
  enterDelay,
}: PropsWithChildren<GraftTooltipProps>) {
  const localizationManager = useLocalizationManager();
  const title = disabled || loading || error || info;

  return (
    <Tooltip
      id={id}
      data-testid={graftTestId}
      placement={placement ?? "top-start"}
      open={open}
      title={
        title === "" || title === true
          ? null
          : localizationManager.maybeGet(title) ?? null
      }
      // NOTE(eschkufz): Override the standard background color for errors
      PopperProps={
        error
          ? {
              sx: {
                ".MuiTooltip-tooltip": { backgroundColor: "error.main" },
              },
            }
          : {}
      }
      followCursor={followCursor}
      enterDelay={enterDelay}
    >
      <div>{children}</div>
    </Tooltip>
  );
}
