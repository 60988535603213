// Copyright © 2021-Present Graft Inc. <copyright@graft.com>

// https://mui.com/x/introduction/licensing/#how-to-install-the-key
import { LicenseInfo } from "@mui/x-license-pro";

//Key is "designed to be public. It's expected that the license key will be exposed in a JavaScript bundle" https://mui.com/x/introduction/licensing/#security
const MUI_LICENSE_KEY =
  "eb9dcbefd17a4f6def699f2aeb532775Tz03MzY2NCxFPTE3MjQ5NTUwODkwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=";

export const setMuiLicense = () => LicenseInfo.setLicenseKey(MUI_LICENSE_KEY);
