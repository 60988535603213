// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../../base/GraftShapeOptions";

export function muiListItemButton(
  palette: GraftPaletteOptions,
  shape: GraftShapeOptions,
) {
  return {
    root: {
      borderRadius: `${shape.borderRadius} ${shape.borderRadius}`,
      "&:hover": {
        backgroundColor: palette.secondary.darker,
      },
      "&.Mui-selected": {
        backgroundColor: palette.common.white,
        "&:hover": {
          backgroundColor: palette.common.white,
        },
      },
    },
  };
}
