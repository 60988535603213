// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { ReactNode } from "react";
import { ContentRecord } from "../../constants/l11n/content";
import { AREG_CONTENT } from "../../constants/l11n/content/ar-eg";
import { ENUS_CONTENT } from "../../constants/l11n/content/en-us";
import { JAJP_CONTENT } from "../../constants/l11n/content/ja-jp";
import { useGraftUserSettings } from "../../hooks/useGraftUser";

const ContentRecords: Record<string, ContentRecord> = {
  "en-us": ENUS_CONTENT,
  "ja-jp": JAJP_CONTENT,
  "ar-eg": AREG_CONTENT,
};

export function useLocalizationManager() {
  const settings = useGraftUserSettings();
  const record =
    ContentRecords[settings.getSettings().localization?.value ?? "en-us"];

  /** Check whether a string is a localization key (i.e. starts with a $ character) */
  const getId = (key: string) => {
    return key.match(/[$](\w+)$/)?.["1"];
  };

  /** Replaces a string with a localization if it's a localization key, otherwise returns it unchanged */
  const get = (key?: string) => {
    if (!key) {
      return undefined;
    }
    const id = getId(key);
    return id ? record[id] ?? undefined : key;
  };

  /** A type-forgiving version of get; only attempts localization if key is a string */
  const maybeGet = (key?: ReactNode) => {
    return typeof key === "string" ? get(key) : key;
  };

  return { get, maybeGet };
}
