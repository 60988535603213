// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

/**
 * Push safe way to support older landing URLs where we only send query params.
 */
export function useLegacyLanding(): boolean {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const redirectPath =
    location.pathname.indexOf("landing/") >= 0
      ? null
      : params.has("organization") &&
          params.has("invitation") &&
          params.has("organization_name")
        ? "landing/invite"
        : null;

  useEffect(() => {
    if (redirectPath) {
      navigate(`${redirectPath}${location.search}`);
    }
  }, [redirectPath]);

  return !!redirectPath;
}
