// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { User } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const SENTRY_WWW_DSN =
  "https://8c154da5fa9a405a926a7c84e568b8d4@o1351925.ingest.sentry.io/6638882";

type SentryUserInfo = {
  id?: string;
  email?: string;
  name?: string;
  org_id: string;
};

export const initSentry = () => {
  const { host } = window.location;
  const isStaging = host === "staging.graft.dev";
  const isDogfood = host === "dogfood.graft.dev";
  const isProd = host.includes("graft.com");

  Sentry.init({
    dsn: SENTRY_WWW_DSN,
    beforeSend: (event) => (isStaging || isProd || isDogfood ? event : null),
    tracesSampleRate: 0.1,

    integrations: [new BrowserTracing()],
  });
};

export const setSentryUser = ({ email, name, org_id, sub: id }: User) => {
  const sentryUser: SentryUserInfo = { id, email, name, org_id };
  Sentry.setUser(sentryUser);
};
