// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftTypographyOptions } from "../../../types/GraftTypographyOptions";

export function muiTableCell(typography: GraftTypographyOptions) {
  return {
    root: {
      ...typography.body1,
      "&.MuiTableCell-head": {
        fontWeight: 500,
      },
    },
  };
}
