// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
/**
 * This is a component used to preload assets needed on/before app load.
 *
 * e.g. fonts needed to prevent font glitching.
 */
import { Helmet } from "react-helmet";

import "@fontsource/commissioner/300.css";
import "@fontsource/commissioner/400.css";

import commissioner300 from "@fontsource/commissioner/files/commissioner-latin-300-normal.woff2";
import commissioner400 from "@fontsource/commissioner/files/commissioner-latin-400-normal.woff2";

export function PreloadedAssets() {
  return (
    <Helmet>
      <link
        rel="preload"
        as="font"
        href={commissioner300}
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href={commissioner400}
        type="font/woff2"
        crossOrigin="anonymous"
      />
    </Helmet>
  );
}
