// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import googleTagManager from "@analytics/google-tag-manager";
import Analytics from "analytics";
import { GraftUserProps, GraftUserVar } from "../../gql/local/GraftUserVar";
import {
  AnalyticsEventMetadata,
  AnalyticsEventType,
  AnalyticsService,
} from "../analytics/types";

const TAG_ID = "GTM-N537HR2";

const analytics = Analytics({
  plugins: [googleTagManager({ containerId: TAG_ID })],
});

export class GraftGoogleTagManager implements AnalyticsService {
  constructor(private user: GraftUserProps) {}

  initialize(): void {
    this.user = GraftUserVar();
    analytics.user(this.user.id);
  }

  track<TMetadata extends AnalyticsEventMetadata>(
    eventType: AnalyticsEventType,
    eventProperties: TMetadata,
  ): void {
    analytics.track(eventType, eventProperties);
  }

  page() {
    analytics.page();
  }
}
