// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { GraftPaletteOptions } from "../../../types/GraftPaletteOptions";

export function muiIconButton(palette: GraftPaletteOptions) {
  return {
    root: {
      "&:hover": { backgroundColor: "transparent" },
      "&.MuiIconButton-colorPrimary": {
        color: palette.primary.main,
      },
      "&.MuiIconButton-colorSecondary": {
        color: palette.secondary.darker,
      },
      "&.MuiIconButton-colorError": {
        color: palette.error.main,
      },
      "&.MuiIconButton-colorInfo": {
        color: palette.info.main,
      },
      "&.MuiIconButton-colorSuccess": {
        color: palette.success.main,
      },
      "&.MuiIconButton-colorWarning": {
        color: palette.warning.main,
      },
      "&.Mui-disabled": {
        color: palette.action.disabled,
      },
    },
  };
}
