// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import ReactDOM from "react-dom";
import App from "./pages/App/App";
import { setMuiLicense } from "./services/muiPro/muiPro";
import { initSentry } from "./services/sentry/sentry";

setMuiLicense();
initSentry();

ReactDOM.render(<App />, document.getElementById("root"));
