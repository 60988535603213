// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import dateFormat, { DateFormatMasks } from "dateformat";

export function unixTimeToDate(epochSeconds: number): Date {
  return new Date(epochSeconds * 1000);
}

export const unixTimeNow = () => Math.floor(Date.now() / 1000);

export const formatDate = (
  d: Date,
  mask: keyof DateFormatMasks = "shortDate",
  utc?: boolean,
): string => {
  return dateFormat(d, mask as string, utc);
};

export const daysAgo = (days: number): Date => {
  const d = new Date();
  d.setDate(d.getDate() - days);
  return d;
};

/* Compact UTC timestamp */
export const formatTimestampForError = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  return dateFormat(date, "yyyymmdd'T'HHMMss", true);
};
