// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

/**
 * Parse invite links to new users.
 * Links are of the form: https://app.graft.com/projects?invitation=<invite_code>&organization=<org_code>&organization_name=<org_name>
 */
export function InviteLink() {
  const location = useLocation();
  const { loginWithRedirect } = useAuth0();
  const params = new URLSearchParams(location.search);
  const organization = params.get("organization"); // Invite link organization
  const invitation = params.get("invitation"); // Invite link invitation
  const valid = organization && invitation;
  useEffect(() => {
    if (!valid) {
      return;
    }
    loginWithRedirect({
      organization,
      invitation,
      redirectUri: window.location.origin + "/projects",
    });
  });

  return valid ? <div>Logging in...</div> : <div>Invalid invitation link.</div>;
}
