// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
/**
 * Note: 300 and 400 are preloaded to avoid font glitching on load.
 * See www/src/pages/PreloadedAssets.tsx for more info.
 */

import "@fontsource/commissioner/100.css";
import "@fontsource/commissioner/200.css";
import "@fontsource/commissioner/500.css";
import "@fontsource/commissioner/600.css";
import "@fontsource/commissioner/700.css";
import "@fontsource/commissioner/800.css";
import "@fontsource/commissioner/900.css";
import { GraftPaletteOptions } from "../../types/GraftPaletteOptions";
import { GraftShapeOptions } from "../base/GraftShapeOptions";
import { GraftTypographyOptions } from "../../types/GraftTypographyOptions";
import { createGraftGlobalStyles } from "../factory/createGraftGlobalStyles";
import { createGraftTheme } from "../factory/createGraftTheme";
import { BreakpointsOptions } from "@mui/system/createTheme/createBreakpoints";

export const GRAFT_DEFAULT_FONT_FAMILY = "Commissioner";

const colors = {
  black: {
    medium: "#000000",
  },

  blue: {
    lighter: "#E0EEFF",
    light: "#C7D9F0",
    medium: "#3884F7",
    dark: "#053986",
  },

  charcoal: {
    lighter: "#F5F5F5",
    light: "#C4C4C4",
    medium: "#949494",
    dark: "#616161",
    darker: "#222222",
  },

  green: {
    lighter: "#EEF9F0",
    light: "#B3DDBF",
    medium: "#208840",
    dark: "#08541F",
  },

  red: {
    lighter: "#FFE5E8",
    light: "#FC9C9D",
    medium: "#E4354A",
    dark: "#CA2F41",
  },

  white: {
    medium: "#FFFFFF",
  },

  yellow: {
    lighter: "#FFF4D8",
    light: "#FEE395",
    medium: "#F9CD54",
    dark: "#F4B202",
  },
};

const weights = {
  light: 300,
  regular: 400,
  medium: 500,
  semiBold: 600,
  bold: 700,
};

const palette: GraftPaletteOptions = {
  common: {
    black: colors.black.medium,
    white: colors.white.medium,
    blue: colors.blue.medium,
    darkBlue: colors.blue.dark,
  },

  primary: {
    lighter: colors.green.lighter,
    light: colors.green.light,
    main: colors.green.medium,
    dark: colors.green.dark,
    darker: colors.green.dark,
  },

  secondary: {
    lighter: colors.charcoal.lighter,
    light: colors.charcoal.light,
    main: colors.charcoal.medium,
    dark: colors.charcoal.dark,
    darker: colors.charcoal.darker,
  },

  error: {
    lighter: colors.red.lighter,
    light: colors.red.light,
    main: colors.red.medium,
    dark: colors.red.dark,
    darker: colors.red.dark,
  },

  warning: {
    lighter: colors.yellow.lighter,
    light: colors.yellow.light,
    main: colors.yellow.medium,
    dark: colors.yellow.dark,
    darker: colors.yellow.dark,
  },

  info: {
    lighter: colors.blue.lighter,
    light: colors.blue.light,
    main: colors.blue.medium,
    dark: colors.blue.dark,
    darker: colors.blue.dark,
  },

  success: {
    lighter: colors.green.lighter,
    light: colors.green.light,
    main: colors.green.medium,
    dark: colors.green.dark,
    darker: colors.green.dark,
  },

  text: {
    primary: colors.charcoal.darker,
    secondary: colors.charcoal.dark,
    disabled: colors.charcoal.medium,
  },

  action: {
    active: colors.charcoal.darker,
    hover: colors.green.medium,
    selected: colors.green.medium,
    disabled: colors.charcoal.medium,
    disabledBackground: colors.charcoal.lighter,
    focus: colors.green.medium,
  },
};

const shape: GraftShapeOptions = {
  borderRadius: 8,
};

const typography: GraftTypographyOptions = {
  fontFamily: GRAFT_DEFAULT_FONT_FAMILY,

  weight: {
    light: weights.light,
    regular: weights.regular,
    medium: weights.medium,
    semiBold: weights.semiBold,
    bold: weights.bold,
  },

  h1: {
    fontSize: "56px",
    letterSpacing: "0.25px",
    fontWeight: weights.regular,
  },

  h2: {
    fontSize: "40px",
    letterSpacing: "0.25px",
    fontWeight: weights.regular,
    lineHeight: 1,
  },

  h3: {
    fontSize: "27px",
    letterSpacing: "0.25px",
    fontWeight: weights.regular,
  },

  h4: {
    fontSize: "23px",
    letterSpacing: "0.15px",
    fontWeight: weights.regular,
  },

  h5: {
    fontSize: "19px",
    letterSpacing: "0.15px",
    fontWeight: weights.regular,
  },

  h6: {
    fontSize: "18px",
    letterSpacing: "0.15px",
    fontWeight: weights.regular,
  },

  body1: {
    fontSize: "14px",
    letterSpacing: "0.25px",
    fontWeight: weights.regular,
  },

  body2: {
    fontSize: "18px",
    letterSpacing: "0.25px",
    fontWeight: weights.light,
    lineHeight: 1.8,
  },

  button: {
    fontSize: "12px",
    letterSpacing: "1.25px",
    fontWeight: weights.regular,
  },

  caption: {
    fontSize: "12px",
    letterSpacing: "0.5px",
    fontWeight: weights.regular,
  },

  overline: {
    fontSize: "11px",
    letterSpacing: "1.5px",
    fontWeight: weights.regular,
  },

  // Custom Typography Variants (see: GraftTypographyOptions.tsx)
  pre: {
    fontFamily: "monospace",
    fontSize: "16px",
    letterSpacing: "inherit",
    fontWeight: weights.regular,
    whiteSpace: "pre-wrap",
    "unicode-bidi": "isolate",
  },

  h7: {
    fontFamily: "Commissioner",
    fontSize: "16px",
    letterSpacing: "normal",
    fontWeight: weights.regular,
  },

  h8: {
    fontFamily: "Commissioner",
    fontSize: "16px",
    letterSpacing: "normal",
    fontWeight: weights.semiBold,
    lineHeight: 1.75,
    color: colors.green.dark,
  },
};

const breakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536,
    xxl: 2200,
  },
};

export const graftGlobalStyles = createGraftGlobalStyles(typography);
export const graftTheme = createGraftTheme(
  palette,
  shape,
  typography,
  breakpoints,
);
