// Copyright © 2021-Present Graft Inc. <copyright@graft.com>
import { Auth0Provider, Auth0ProviderOptions } from "@auth0/auth0-react";
import { FC } from "react";
import { useGraftNavigate } from "../hooks/navigation/useGraftNavigate";

type GraftAuth0ProviderProps = Partial<Auth0ProviderOptions>;

export const GraftAuth0Provider: FC<GraftAuth0ProviderProps> = ({
  children,
  ...rest
}) => {
  return (
    <Auth0Provider
      domain="auth.graft.com"
      clientId="Fdc2CAQefHzfCeNaWYiZks88D19m0PEE"
      audience="https://www.graft.dev/v1"
      useRefreshTokens={true}
      cookieDomain={
        window.location.hostname.match(/.*([.]graft[.]\w*)$/)?.at(1) ??
        window.location.hostname
      }
      {...rest}
    >
      {children}
    </Auth0Provider>
  );
};

/**
 * A provider for authenticating to Auth0 with a redirect specific to the main
 * Graft web application.
 *
 * NOTE (patmigliaccio): `useGraftNavigate()` requires `<Router />` which is not
 * used by all client applications (i.e. Chrome Extension).
 */
export const GraftAuth0ProviderWithAppRedirect: FC<GraftAuth0ProviderProps> = ({
  children,
  ...rest
}) => {
  const graftNavigate = useGraftNavigate();

  return (
    <GraftAuth0Provider
      {...rest}
      onRedirectCallback={(appState) => {
        graftNavigate(appState?.returnTo || window.location.pathname, null, {
          replace: true,
        });
      }}
    >
      {children}
    </GraftAuth0Provider>
  );
};
